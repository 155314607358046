import { useState } from 'react';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';

export interface DeviceTelemetry {
    id: number;
    deviceKeyId: string;
    deviceId: string;
    deviceType: string;
    deviceBrand: string;
    deviceModel: string;
    deviceOsVersion: string;
    deviceOsName: string;
    deviceOsVersionNumber: string;
    telemetryData: Record<string, unknown>;
    createdAt: string;
    updatedAt: string;
}

export interface QueueData {
    deviceKeyId: string;
    deviceId: string;
    queueData: Record<string, unknown>;
    updatedAt: string;
}

export default function useDeviceTelemetry() {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getLatestDeviceTelemetry = async (
        orgId: number,
        deviceId: string,
    ): Promise<DeviceTelemetry | null> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(
                `/${orgId}/device-telemetry/device/${deviceId}/latest`,
            );
            return response.data;
        } catch (err) {
            console.error('Error fetching device telemetry:', err);
            setError('Failed to fetch telemetry data');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const listDeviceTelemetry = async (
        orgId: number,
    ): Promise<DeviceTelemetry[]> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(
                `/${orgId}/device-telemetry`,
            );
            return response.data;
        } catch (err) {
            console.error('Error listing device telemetry:', err);
            setError('Failed to list telemetry data');
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    const getQueueDataForActivation = async (
        orgId: number,
        activationId: number,
    ): Promise<QueueData[]> => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authenticatedApi.get(
                `/${orgId}/device-telemetry/activation/${activationId}/queue`,
            );
            return response.data;
        } catch (err) {
            console.error('Error fetching queue data:', err);
            setError('Failed to fetch queue data');
            return [];
        } finally {
            setIsLoading(false);
        }
    };

    return {
        getLatestDeviceTelemetry,
        listDeviceTelemetry,
        getQueueDataForActivation,
        isLoading,
        error,
    };
}
