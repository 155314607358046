import api from '@/src/services/api';

export default function useActivationPayments() {
    const getActivationPaymentSettings = async (activationId: number) => {
        const response = await api.get(
            `/payments/${activationId}/paymentSettings`,
        );
        return response.data;
    };

    const markPaymentAsPaid = async (
        redemptionCode: string,
        activationId: number,
        paymentIntentId: string,
    ) => {
        const response = await api.put(
            `/payments/${activationId}/sessionPayments/pay`,
            {
                redemptionCode,
                activationId,
                paymentIntentId,
            },
        );
        return response.data;
    };

    const markTokenAsClaimed = async (
        redemptionCode: string,
        activationId: number,
    ) => {
        const response = await api
            .put(`/payments/${activationId}/sessionPayments/claim`, {
                redemptionCode,
                activationId,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            });
        return response;
    };

    const createPaymentIntent = async (
        activationId: number,
        redemptionCode: string,
    ) => {
        const response = await api.post(
            `/payments/${activationId}/sessionPayments/createPaymentIntent`,
            {
                activationId,
                redemptionCode,
            },
        );
        return response.data;
    };

    return {
        getActivationPaymentSettings,
        markPaymentAsPaid,
        markTokenAsClaimed,
        createPaymentIntent,
    };
}
