import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
} from '../../shadComponents/ui/card';
import moment from 'moment-timezone';
import useDeviceTelemetry, {
    DeviceTelemetry,
} from '../hooks/useDeviceTelemetry';
import Loading from '@/src/Common/components/Loading';

type RouteParams = {
    [key: string]: string | undefined;
    orgId: string;
    deviceId: string;
};

export const DeviceTelemetryView: React.FC = () => {
    const { orgId, deviceId } = useParams<RouteParams>();
    const [telemetry, setTelemetry] = useState<DeviceTelemetry | null>(null);
    const [batteryLevel, setBatteryLevel] = useState<number | null>(null);
    const [batteryStatus, setBatteryStatus] = useState<string | null>(null);
    const [networkName, setNetworkName] = useState<string | null>(null);
    const [networkType, setNetworkType] = useState<string | null>(null);

    const { getLatestDeviceTelemetry, isLoading, error } = useDeviceTelemetry();

    useEffect(() => {
        const fetchTelemetry = async () => {
            if (!orgId || !deviceId) {
                return;
            }
            const data = await getLatestDeviceTelemetry(
                Number(orgId),
                deviceId,
            );
            if (data) {
                setTelemetry(data);
                let telemetryData = data.telemetryData;

                if (telemetryData?.battery) {
                    // @ts-ignore
                    setBatteryLevel(telemetryData?.battery?.level);
                    // @ts-ignore
                    setBatteryStatus(telemetryData?.battery?.state);
                }

                if (telemetryData?.network) {
                    // @ts-ignore
                    setNetworkName(telemetryData?.network?.networkSSID);
                    // @ts-ignore
                    setNetworkType(telemetryData?.network?.connectivity);
                }
            }
        };

        fetchTelemetry();

        let interval = setInterval(fetchTelemetry, 10000);
        return () => clearInterval(interval);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    if (error) {
        return (
            <CardContent className="pt-6">
                <div className="">No telemetry data found</div>
            </CardContent>
        );
    }

    if (isLoading && !telemetry) {
        return <Loading isLoading={true} />;
    }

    if (!telemetry) {
        return (
            <CardContent className="pt-6">
                <div>No telemetry data found</div>
            </CardContent>
        );
    }

    return (
        <div className="space-y-6">
            <CardHeader>
                <CardTitle>Device Information</CardTitle>
                <CardDescription>
                    Latest telemetry data for device {telemetry.deviceId}
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="relative w-full overflow-auto">
                    <table className="w-full caption-bottom text-sm">
                        <tbody className="[&_tr:last-child]:border-0">
                            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                <td className="p-4 align-middle font-medium">
                                    Device ID
                                </td>
                                <td className="p-4 align-middle">
                                    {telemetry.deviceId}
                                </td>
                            </tr>
                            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                <td className="p-4 align-middle font-medium">
                                    Device Type
                                </td>
                                <td className="p-4 align-middle">
                                    {telemetry.deviceType}
                                </td>
                            </tr>
                            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                <td className="p-4 align-middle font-medium">
                                    Brand
                                </td>
                                <td className="p-4 align-middle">
                                    {telemetry.deviceBrand}
                                </td>
                            </tr>
                            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                <td className="p-4 align-middle font-medium">
                                    Model
                                </td>
                                <td className="p-4 align-middle">
                                    {telemetry.deviceModel}
                                </td>
                            </tr>
                            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                <td className="p-4 align-middle font-medium">
                                    OS
                                </td>
                                <td className="p-4 align-middle">
                                    {telemetry.deviceOsName}{' '}
                                    {telemetry.deviceOsVersion}
                                </td>
                            </tr>
                            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                <td className="p-4 align-middle font-medium">
                                    OS Version Number
                                </td>
                                <td className="p-4 align-middle">
                                    {telemetry.deviceOsVersionNumber}
                                </td>
                            </tr>
                            <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                <td className="p-4 align-middle font-medium">
                                    Last Updated
                                </td>
                                <td className="p-4 align-middle">
                                    {moment(telemetry.updatedAt).format(
                                        'MM/DD/YYYY hh:mm:ss A',
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardContent>

            <CardHeader>
                <CardTitle>Telemetry Data</CardTitle>
                <CardDescription>
                    Detailed telemetry information
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="relative w-full overflow-auto">
                    <table className="w-full caption-bottom text-sm">
                        <tbody className="[&_tr:last-child]:border-0">
                            {batteryLevel && (
                                <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <td className="p-4 align-middle font-medium">
                                        Battery Level
                                    </td>
                                    <td className="p-4 align-middle">
                                        {batteryLevel.toFixed(0)}%
                                    </td>
                                </tr>
                            )}
                            {batteryStatus && (
                                <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <td className="p-4 align-middle font-medium">
                                        Battery Status
                                    </td>
                                    <td className="p-4 align-middle">
                                        {batteryStatus}
                                    </td>
                                </tr>
                            )}
                            {networkType && (
                                <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <td className="p-4 align-middle font-medium">
                                        Network Type
                                    </td>
                                    <td className="p-4 align-middle">
                                        {networkType}
                                    </td>
                                </tr>
                            )}
                            {networkName && (
                                <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                                    <td className="p-4 align-middle font-medium">
                                        Network Name
                                    </td>
                                    <td className="p-4 align-middle">
                                        {networkName}
                                    </td>
                                </tr>
                            )}

                            {Object.entries(telemetry.telemetryData)
                                .filter(
                                    ([key, value]) =>
                                        key !== 'battery' && key !== 'network',
                                )
                                .map(([key, value]) => (
                                    <tr
                                        key={key}
                                        className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
                                    >
                                        <td className="p-4 align-middle font-medium capitalize">
                                            {key}
                                        </td>
                                        <td className="p-4 align-middle">
                                            {JSON.stringify(value)}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </CardContent>
        </div>
    );
};
