import React, { useEffect, useState } from 'react';
import useDeviceTelemetry from '../../DeviceTelemetry/hooks/useDeviceTelemetry';
import Loading from '../../Common/components/Loading';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';
import moment from 'moment-timezone';
import { Tablet } from 'lucide-react';
import { Link } from 'react-router-dom';

interface QueueData {
    deviceKeyId: string;
    deviceId: string;
    queueData: Record<string, unknown>;
    updatedAt: string;
}

interface ActivationQueueDataProps {
    activationId: number;
}

export const ActivationQueueData: React.FC<ActivationQueueDataProps> = ({
    activationId,
}) => {
    const [queueData, setQueueData] = useState<QueueData[]>([]);
    const { getQueueDataForActivation, isLoading, error } =
        useDeviceTelemetry();

    const { orgId } = useUserOrg();

    useEffect(() => {
        const fetchQueueData = async () => {
            const data = await getQueueDataForActivation(orgId, activationId);
            if (data) {
                setQueueData(data);
            }
        };

        fetchQueueData();

        let timer = setInterval(() => {
            fetchQueueData();
        }, 10000);

        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (error) {
        return <div className="text-red-500">Failed to load queue data</div>;
    }

    if (isLoading && !queueData.length) {
        return <Loading isLoading={true} />;
    }

    if (!queueData.length) {
        return <div>No queue data available</div>;
    }

    return (
        <div className="relative w-full overflow-auto">
            <table className="w-full caption-bottom text-sm">
                <thead className="[&_tr]:border-b">
                    <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                        <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
                            Uploaded
                        </th>
                        <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
                            Remaining
                        </th>
                        <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
                            Last Updated
                        </th>
                    </tr>
                </thead>
                <tbody className="[&_tr:last-child]:border-0">
                    {queueData.map((item, index) => (
                        <tr
                            key={item.deviceId + item.deviceKeyId + index}
                            className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted"
                        >
                            <td className="p-4 align-middle font-medium">
                                {/* @ts-ignore */}
                                {item.queueData?.uploaded}
                            </td>
                            <td className="p-4 align-middle">
                                {/* @ts-ignore */}
                                {item.queueData?.unuploaded}
                            </td>
                            <td className="p-4 align-middle">
                                {moment(item.updatedAt).toNow(true)} ago
                            </td>
                            <td className="p-4 align-middle">
                                <Link
                                    to={`/orgs/${orgId}/device-telemetry/${item.deviceKeyId}`}
                                >
                                    <div className="flex items-center justify-center bg-black text-white rounded-md border border-border py-2">
                                        <Tablet className="h-4 w-4" />
                                        <span className="ml-2">
                                            View Device
                                        </span>
                                    </div>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
