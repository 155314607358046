import { useState, useMemo, useEffect } from 'react';
import { useLocalStorage } from '../../../Common/components/hooks/useLocalStorage';
import useQueryString from '../../../Common/components/hooks/useQueryString';

export default function useAutoShare(
    galleryId: number,
    gallery: GalleryObject | undefined | null,
) {
    const query = useQueryString();
    const shareDestinationKey = `sb-share-destination-${galleryId}`;
    const shareTypeKey = `sb-share-type-${galleryId}`;

    // eslint-disable-next-line eqeqeq
    if (galleryId == 0) {
        console.error('galleryId must be set');
    }

    const shareIdentifiers = useMemo(
        () =>
            query
                .getAll('shareIdentifier')
                .map((item) => item.split(','))
                .flat(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [galleryId],
    );

    const [shareDestination, setShareDestination] = useLocalStorage<string>(
        shareDestinationKey,
        '',
    );

    const [lastSharedIdentifier, setLastSharedIdentifier] =
        useLocalStorage<string>(`sb-last-shared-identifier`, '');

    const wasLastShared = lastSharedIdentifier === shareIdentifiers.toString();

    const isTextOnly =
        gallery?.emailEnabled === false && gallery?.textEnabled === true;
    const isEmailOnly =
        gallery?.emailEnabled === true && gallery?.textEnabled === false;

    const [shareType, setShareType] = useLocalStorage<ShareType | null>(
        shareTypeKey,
        null,
    );

    useEffect(() => {
        if (isTextOnly) {
            setShareType(1);
        } else if (isEmailOnly) {
            setShareType(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTextOnly, isEmailOnly]);

    const [autoShare, setAutoShare] = useState(
        !!shareDestination && shareType !== null,
    );

    const [isSharing, setIsSharing] = useState(false);

    return {
        isSharing,
        setIsSharing,
        autoShare,
        setAutoShare,
        shareType,
        setShareType,
        shareDestination,
        setShareDestination,
        setLastSharedIdentifier,
        wasLastShared,
        shareDestinationKey,
        shareTypeKey,
    };
}
