export default function PageWithTable({
    children,
    title,
    subtitle,
    actions,
    filters = null,
}: any) {
    return (
        <main>
            <div className="relative isolate w-full overflow-hidden">
                <header className="pb-4 pt-6 sm:pb-6">
                    <div className="mx-auto flex max-w-10xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                        <div>
                            <h1 className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                {title}
                            </h1>
                            {subtitle && (
                                <p className="text-sm text-gray-500 mt-2">
                                    {subtitle}
                                </p>
                            )}
                        </div>
                        <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                            {filters ? filters : null}
                        </div>
                        <div className="spacer"></div>
                        {actions ? actions : null}
                    </div>
                </header>
                {children}
            </div>
        </main>
    );
}
