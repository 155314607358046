import { Suspense, useContext, useEffect, useRef, memo, useState } from 'react';
import GalleryContext from '@/src/Gallery/components/GalleryContext';
import { ErrorBoundary } from 'react-error-boundary';
import placeholder from './placeholder.jpg';
import GalleryShareContext from './GalleryShareContext';
import VideoPlayer from './VideoPlayer';
import usePhotoViewLogger from '@/src/Stats/components/hooks/usePhotoViewLogger';
import DownloadButtonV2 from '@/src/Share/components/DownloadButtonV2';

const VIDEO_MIME_TYPE = 'video/mp4';

function PlaceholderImage() {
    return <img src={placeholder} alt="" />;
}

interface ErrorFallbackProps {
    error: any;
    resetErrorBoundary: any;
}

function ErrorFallback({ resetErrorBoundary }: ErrorFallbackProps) {
    useEffect(() => {
        setTimeout(resetErrorBoundary, 100);
    });

    return <PlaceholderImage />;
}

export const GifWrapper = memo(({ url }: { url: string }) => {
    return (
        <div className="Gif-frame">
            <img src={url} alt="" draggable={false} />
            <div className="button-container">
                <i
                    className="bi bi-play"
                    style={{ filter: 'drop-shadow(0px 0px 5px #222)' }}
                />
            </div>
        </div>
    );
});

function ImageWrapper({ media, isExpanded }: GalleryPreviewImageProps) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [uri, setUri] = useState<string>(
        media.thumbnailTinyUri || media.thumbnail_800pxUri || media.originalUri,
    );

    useEffect(() => {
        const image = new Image();
        image.src = media.originalUri;
        image.onload = () => {
            setUri(media.originalUri);
            setIsLoading(false);
        };

        return () => {
            image.onload = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uri, media]);

    return (
        <div className="relative w-full h-full">
            {isLoading && <div className="loader absolute " />}

            <img
                src={uri}
                loading="lazy"
                alt=""
                draggable={false}
                className={`rounded-[0px] w-full h-full object-cover ${
                    isLoading ? '' : ''
                }`}
            />
        </div>
    );
}

export function MediaWrapper(props: GalleryPreviewImageProps) {
    const { media, isExpanded } = props;
    const { originalUri, thumbnailUri, mimeType } = media;

    let component = null;
    if (mimeType === VIDEO_MIME_TYPE && isExpanded) {
        component = <VideoPlayer url={originalUri} showControls={false} />;
    } else if (mimeType === VIDEO_MIME_TYPE) {
        component = <GifWrapper url={thumbnailUri} />;
    } else {
        component = <ImageWrapper {...props} key={media.id} />;
    }

    return (
        <div className="Media-container">
            {component}
            <div className="click-blocker" />
        </div>
    );
}

interface GalleryPreviewImageProps {
    media: MediaInstance;
    isExpanded?: boolean;
    galleryInfo?: any;
    index?: number;
    setExpandedIndex?: (index: any) => void;
}

function VisibleImage(props: GalleryPreviewImageProps) {
    const delayed = useRef(false);

    useEffect(() => {
        setTimeout(() => (delayed.current = true), 100);
    }, []);

    return <MediaWrapper {...props} />;
}

export default function GalleryLightbox({
    photos,
    currentPhotoIndex,
    gestureHandlers,
}: {
    photos: MediaInstance[];
    currentPhotoIndex: number;
    gestureHandlers: any;
}) {
    const { shareIdentifier, setShareIdentifier } = useContext(GalleryContext);
    const galleryShareContext = useContext(GalleryShareContext);

    const photo = photos.find((p) => p.shareIdentifier === shareIdentifier);

    const { logPhotoView } = usePhotoViewLogger();
    const viewSent = useRef(false);

    useEffect(() => {
        if (photo && !viewSent.current) {
            logPhotoView(photo.activationId, photo.id);
            viewSent.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photo]);

    if (!photo) {
        return null;
    }

    const { mimeType } = photo;

    const isImage =
        mimeType?.toLowerCase() === 'image/jpeg' ||
        mimeType?.toLowerCase() === 'image/jpg';

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
            <Suspense>
                <div className="expanded-view" {...gestureHandlers}>
                    <div
                        className={`AbsoluteGallery-cell expanded${
                            isImage ? ' image' : ''
                        }`}
                    >
                        <div className="absolute top-0 left-0 w-full h-[80px] z-[1000]">
                            <div
                                className={`visible md:invisible options flex flex-row justify-center items-center gap-2 ${
                                    isImage ? '' : 'video'
                                } z-100`}
                            >
                                <DownloadButtonV2
                                    uri={photo.originalUri}
                                    mimeType={photo.mimeType}
                                    mediaId={photo.id}
                                    source="gallery"
                                />

                                {galleryShareContext && (
                                    <i
                                        onClick={() => {
                                            galleryShareContext.setShowShareModal(
                                                photo.shareIdentifier,
                                            );
                                        }}
                                        className="bi bi-send save-button"
                                    ></i>
                                )}
                                <div className="spacer" />
                                <div className="text-sm">
                                    <b>{currentPhotoIndex + 1}&nbsp;</b> /&nbsp;
                                    {photos.length}
                                </div>
                                <div className="spacer" />
                                <div
                                    className="text-[28px]"
                                    onClick={() => {
                                        setShareIdentifier(null);
                                    }}
                                >
                                    <i className="bi bi-x text-[28px] w-[28px] h-[28px]"></i>
                                </div>
                            </div>

                            <div className="invisible md:visible">
                                <div className="spacer" />
                                <div
                                    className="Close-lightbox"
                                    onClick={() => {
                                        setShareIdentifier(null);
                                    }}
                                >
                                    <i className="bi bi-x w-[28px] h-[28px]"></i>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`mt-20 md:mt-0 ${
                                isImage ? '' : 'pt-[30px]'
                            }`}
                        >
                            <VisibleImage media={photo} isExpanded={true} />
                        </div>

                        <div
                            className={`invisible absolute text-[22px] bottom-5 w-full md:visible flex flex-row justify-center items-center gap-2 ${
                                isImage ? '' : 'video'
                            } z-1000`}
                        >
                            <div className="spacer" />
                            <DownloadButtonV2
                                uri={photo.originalUri}
                                mimeType={photo.mimeType}
                                mediaId={photo.id}
                                source="gallery"
                            />

                            {galleryShareContext && (
                                <i
                                    onClick={() => {
                                        galleryShareContext.setShowShareModal(
                                            photo.shareIdentifier,
                                        );
                                    }}
                                    className="bi bi-send save-button ml-2"
                                ></i>
                            )}
                            <div className="spacer" />
                        </div>
                        <div className="absolute invisible md:visible bottom-5 right-5 w-[200px] flex justify-end items-end">
                            <b>{currentPhotoIndex + 1}&nbsp;</b> /&nbsp;
                            {photos.length}
                        </div>
                    </div>
                </div>
            </Suspense>
        </ErrorBoundary>
    );
}
