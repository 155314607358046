import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useActivation from './hooks/useActivation';
import PhotoManagerList from './PhotoManagerList';
import BulkUploadMedia from '@/src/Common/components/BulkUploadMedia';
import Loading from '@/src/Common/components/Loading';
import ZipPrompt from './ZipPrompt';
import DownloadRequestList from './DownloadRequestList';
import PhotoManagerFilters from './PhotoManagerFilters';

export default function PhotoManager() {
    const [showHidden, setShowHidden] = useState(false);
    const { activationId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [, setActivation] = useState<ActivationObject | null>(null);
    const [photos, setPhotos] = useState<MediaInstance[]>([]);
    const [allPhotos, setAllPhotos] = useState<MediaInstance[]>([]);
    const [typeFilter, setTypeFilter] = useState<string[]>([]);
    const [isShowingBulkUpload, setIsShowingBulkUpload] = useState(false);
    const [isShowingZipPrompt, setIsShowingZipPrompt] = useState(false);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [selectedShareIdentifiers, setSelectedShareIdentifiers] = useState<
        string[]
    >([]);

    const { getActivation, getActivationPhotos } = useActivation();

    const [columnCount, setColumnCount] = useState(4);

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const activation = await getActivation(
                    activationId ? +activationId : 0,
                );

                setActivation(activation);

                const photos = await getActivationPhotos(
                    activation.id,
                    typeFilter,
                );

                setAllPhotos(photos);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let sortedPhotos = [...allPhotos].sort((a, b) => {
            return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
        });

        if (!showHidden) {
            sortedPhotos = sortedPhotos.filter(
                (photo: MediaInstance) => !photo.isHidden,
            );
        }

        sortedPhotos = sortedPhotos.filter((photo: MediaInstance) =>
            typeFilter.includes(photo.smileportType || 'processed'),
        );

        setPhotos(sortedPhotos);
    }, [allPhotos, typeFilter, showHidden, sortOrder]);

    useEffect(() => {
        if (sortOrder === 'asc') {
            setPhotos(
                [...photos].sort((a, b) => {
                    return a.id - b.id;
                }),
            );
        } else {
            setPhotos(
                [...photos].sort((a, b) => {
                    return b.id - a.id;
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder]);

    const [refreshCount, setRefreshCount] = useState(0);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div>
            <DownloadRequestList
                key={refreshCount}
                activationId={activationId ? +activationId : 0}
            />
            <PhotoManagerFilters
                photos={photos}
                onFilterChange={setTypeFilter}
                onSortChange={setSortOrder}
                activationId={activationId ? +activationId : 0}
                isShowingZipPrompt={isShowingZipPrompt}
                setIsShowingZipPrompt={setIsShowingZipPrompt}
                isShowingBulkUpload={isShowingBulkUpload}
                setIsShowingBulkUpload={setIsShowingBulkUpload}
                showHidden={showHidden}
                setShowHidden={setShowHidden}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                selectedShareIdentifiers={selectedShareIdentifiers}
                setPhotos={setPhotos}
                setSelectedShareIdentifiers={setSelectedShareIdentifiers}
                setColumnCount={setColumnCount}
                columnCount={columnCount}
            />
            <PhotoManagerList
                photos={photos}
                setSelectedShareIdentifiers={setSelectedShareIdentifiers}
                selectedShareIdentifiers={selectedShareIdentifiers}
                columnCount={columnCount}
            />

            {isShowingBulkUpload ? (
                <BulkUploadMedia
                    onCancel={() => setIsShowingBulkUpload(false)}
                    onComplete={() => {
                        setIsShowingBulkUpload(false);
                        alert('upload complete');
                    }}
                    activationId={activationId ? +activationId : 0}
                />
            ) : null}
            {isShowingZipPrompt ? (
                <ZipPrompt
                    shareIdentifiers={photos.map(
                        (photo) => photo.shareIdentifier,
                    )}
                    activationId={activationId ? +activationId : 0}
                    onComplete={() => {
                        setIsShowingZipPrompt(false);
                        setRefreshCount(refreshCount + 1);
                    }}
                />
            ) : null}
        </div>
    );
}
