import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button } from '@/src/shadComponents/ui/button';
import Table, { createColumnHelper } from '../../Common/components/Table';
import useLicenseTypeManagement from './hooks/useLicenseTypeManagement';
import Loading from '../../Common/components/Loading';
import { toast } from 'sonner';
import useFeatureFlagManagment from '@/src/Features/components/hooks/useFeatureFlagManagement';

interface Feature {
    id: number;
    key: string;
}

interface LicenseTypeFeature {
    id: number;
    licenseTypeId: number;
    featureId: number;
    key: string;
}

const featureColumnHelper = createColumnHelper<Feature>();

export default function EditLicenseTypeFeatures() {
    const navigate = useNavigate();
    const { licenseTypeId } = useParams();
    const [features, setFeatures] = useState<Feature[]>([]);
    const [selectedFeatures, setSelectedFeatures] = useState<
        LicenseTypeFeature[]
    >([]);
    const [licenseTypeName, setLicenseTypeName] = useState<string>('');
    const {
        getLicenseType,
        listFeaturesForLicenseType,
        addFeatureToLicenseType,
        removeFeatureFromLicenseType,
        isLoading,
        setIsLoading,
    } = useLicenseTypeManagement();

    const { fetchFeatureFlags } = useFeatureFlagManagment();

    const featureColumnDefinitions = [
        featureColumnHelper.accessor('key', {
            id: 'key',
            header: () => <span>Feature</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        featureColumnHelper.display({
            id: 'actions',
            cell: (props) => {
                const feature = props.row.original;
                const isSelected = selectedFeatures.some(
                    (f) => f.featureId === feature.id,
                );

                return (
                    <Button
                        variant={isSelected ? 'destructive' : 'outline'}
                        size="sm"
                        onClick={() => toggleFeature(feature)}
                    >
                        {isSelected ? 'Remove' : 'Add'}
                    </Button>
                );
            },
        }),
    ];

    const toggleFeature = async (feature: Feature) => {
        if (!licenseTypeId) return;

        const isSelected = selectedFeatures.some(
            (f) => f.featureId === feature.id,
        );

        try {
            if (isSelected) {
                await removeFeatureFromLicenseType(
                    parseInt(licenseTypeId),
                    feature.id,
                );
                setSelectedFeatures(
                    selectedFeatures.filter((f) => f.featureId !== feature.id),
                );
                toast.success(`Removed feature: ${feature.key}`);
            } else {
                const newFeature = await addFeatureToLicenseType(
                    parseInt(licenseTypeId),
                    feature.id,
                );
                setSelectedFeatures([...selectedFeatures, newFeature]);
                toast.success(`Added feature: ${feature.key}`);
            }
        } catch (error) {
            console.error('Failed to toggle feature:', error);
            if (error instanceof Error) {
                toast.error(error.message || 'Failed to toggle feature');
            }
        }
    };

    useEffect(() => {
        let isMounted = true;

        const load = async () => {
            if (!licenseTypeId) return;

            try {
                const [licenseType, features, featureFlags] = await Promise.all(
                    [
                        getLicenseType(parseInt(licenseTypeId)),
                        listFeaturesForLicenseType(parseInt(licenseTypeId)),
                        fetchFeatureFlags(),
                    ],
                );

                if (isMounted) {
                    setLicenseTypeName(licenseType.name);
                    setSelectedFeatures(features);
                    setFeatures(featureFlags);
                }
            } catch (error) {
                console.error('Failed to load license type features:', error);
                if (error instanceof Error) {
                    toast.error(
                        error.message || 'Failed to load license type features',
                    );
                }
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [licenseTypeId]);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    return (
        <div className="px-8 py-6">
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h1 className="text-2xl font-semibold text-gray-900">
                        {licenseTypeName} Features
                    </h1>
                    <p className="mt-1 text-sm text-gray-500">
                        Manage which features are available with this license
                        type.
                    </p>
                </div>
                <Button variant="outline" onClick={() => navigate(-1)}>
                    Done
                </Button>
            </div>

            <Table data={features} columns={featureColumnDefinitions} />
        </div>
    );
}
