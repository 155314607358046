import '../../Common/components/Form.css';
import { Formik, Form, Field } from 'formik';
import useFeatureFlagManagement from './hooks/useFeatureFlagManagement';
import { useNavigate } from 'react-router';

interface CreateFeatureFlagFormValues {
    key: string;
    enabled: boolean;
    enabledForDevelopment: boolean;
    isPublic: boolean;
}

export default function CreateFeatureFlag() {
    const initialValues: CreateFeatureFlagFormValues = {
        key: '',
        enabled: false,
        enabledForDevelopment: true,
        isPublic: false,
    };

    const navigate = useNavigate();
    const { createFeatureFlag } = useFeatureFlagManagement();

    return (
        <div className="">
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        Create
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Create A Feature Flag
                                </div>
                                <div className="mt-1 text-sm leading-6 font-light text-gray-500">
                                    Create a new feature flag.
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting }) => {
                    await createFeatureFlag(values);
                    setSubmitting(false);

                    navigate(-1);
                }}
            >
                {({ isSubmitting }) => (
                    <Form
                        className="mt-6"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div className="space-y-12">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10  pb-12 md:grid-cols-3">
                                <div className="px-8">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        Feature Flag Details
                                    </h2>
                                </div>
                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 p-4 sm:p-0">
                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="key"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Name
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    id="key"
                                                    name="key"
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 focus:outline-none m:text-sm m:leading-6 border-none"
                                                    placeholder="Feature Flag Key"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-full flex items-center gap-x-2">
                                        <label htmlFor="enabled">Enabled</label>
                                        <div className="left-justify">
                                            <Field
                                                id="enabled"
                                                name="enabled"
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-full flex items-center gap-x-2">
                                        <label htmlFor="enabledForDevelopment">
                                            Development Enabled
                                        </label>
                                        <div className="left-justify">
                                            <Field
                                                id="enabledForDevelopment"
                                                name="enabledForDevelopment"
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-full flex items-center gap-x-2">
                                        <label htmlFor="isPublic">
                                            Public Facing
                                        </label>
                                        <div className="left-justify">
                                            <Field
                                                id="isPublic"
                                                name="isPublic"
                                                type="checkbox"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </button>

                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            >
                                Create
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
