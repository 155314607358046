import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Table, { createColumnHelper } from '../../Common/components/Table';
import PageWithTable from '@/src/Common/components/PageWithTable';
import { Button } from '@/src/shadComponents/ui/button';
import useLicenseTypeManagement from './hooks/useLicenseTypeManagement';
import { toast } from 'sonner';

interface LicenseType {
    id: number;
    name: string;
    key: string;
    description: string | null;
    adminName: string;
    simultaneousDeviceCount: number;
}

interface LicenseTypeFeature {
    id: number;
    licenseTypeId: number;
    featureId: number;
    key: string;
}

const licenseTypeColumnHelper = createColumnHelper<LicenseType>();
const featureColumnHelper = createColumnHelper<LicenseTypeFeature>();

export default function LicenseTypes() {
    const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
    const [licenseTypeFeatures, setLicenseTypeFeatures] = useState<
        LicenseTypeFeature[]
    >([]);
    const {
        isLoading,
        fetchLicenseTypes,
        listFeaturesForLicenseType,
        setIsLoading,
    } = useLicenseTypeManagement();
    const navigate = useNavigate();

    const licenseTypeColumnDefinitions = [
        licenseTypeColumnHelper.accessor('name', {
            id: 'name',
            header: () => <span>Name</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        licenseTypeColumnHelper.accessor('adminName', {
            id: 'adminName',
            header: () => <span>Admin Name</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        licenseTypeColumnHelper.accessor('simultaneousDeviceCount', {
            id: 'deviceLimit',
            header: () => <span>Device Limit</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        licenseTypeColumnHelper.display({
            id: 'actions',
            header: () => <span>Actions</span>,
            cell: (info) => (
                <div className="flex justify-end gap-2">
                    <Button
                        variant="outline"
                        onClick={() =>
                            navigate(`/admin/licenses/${info.row.original.id}`)
                        }
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outline"
                        onClick={() =>
                            navigate(
                                `/admin/licenses/${info.row.original.id}/features`,
                            )
                        }
                    >
                        Features
                    </Button>
                </div>
            ),
        }),
    ];

    const featureColumnDefinitions = [
        featureColumnHelper.accessor('key', {
            id: 'key',
            header: () => <span>Feature</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        featureColumnHelper.accessor('licenseTypeId', {
            id: 'licenseType',
            header: () => <span>License Type</span>,
            cell: (info) => {
                const licenseType = licenseTypes.find(
                    (type) => type.id === info.getValue(),
                );
                return <span>{licenseType?.name || 'Unknown'}</span>;
            },
        }),
    ];

    const loadData = async () => {
        try {
            const types = await fetchLicenseTypes();
            setLicenseTypes(types);

            const features: LicenseTypeFeature[] = [];
            for (const type of types) {
                const typeFeatures = await listFeaturesForLicenseType(type.id);
                features.push(...typeFeatures);
            }
            setLicenseTypeFeatures(features);
        } catch (error) {
            console.error('Failed to load data:', error);
            if (error instanceof Error) {
                toast.error(error.message || 'Failed to load data');
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const load = async () => {
            await loadData();
            isMounted && setIsLoading(false);
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const actions = (
        <div className="flex justify-end">
            <NavLink to="create">
                <Button className="Primary-button">
                    + Create License Type
                </Button>
            </NavLink>
        </div>
    );

    return (
        <PageWithTable
            title="License Types"
            actions={actions}
            subtitle="License types define the features available to organizations."
        >
            <Table data={licenseTypes} columns={licenseTypeColumnDefinitions} />
            <h3 className="text-lg font-semibold leading-6 text-gray-900 pl-7 pt-8 pb-2">
                Feature Access
            </h3>
            <p className="text-sm text-gray-500 pl-7 pb-4">
                Manage which features are available for each license type.
            </p>
            <Table
                data={licenseTypeFeatures}
                columns={featureColumnDefinitions}
            />
            <br />
            <br />
            <br />
        </PageWithTable>
    );
}
