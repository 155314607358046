import { useParams } from 'react-router-dom';
import './Gallery.css';
import GalleryView from './GalleryView';
import { useCallback, useMemo, useState } from 'react';
import GalleryShareContext from './GalleryShareContext';
import ShareModal from './ShareModal';
import useSessionGalleryInteractions from './hooks/useSessionGalleryInteractions';
import GlobalStyle from '@/src/Share/components/GalleryTheme';
import useQueryString from '@/src/Common/components/hooks/useQueryString';
import AbsoluteGallery from './AbsoluteGallery';
import FeatureFlag from '@/src/Features/components/FeatureFlag';
import { FeatureFlags } from '@/src/Features/lib/constants';

export default function SessionGallery() {
    const { photoSessionId, galleryId } = useParams();
    const query = useQueryString();
    const useV2 = query.get('v2') === 'true';
    const {
        gallery,
        isLoading,
        photos,
        delayed,
        expandedIndex,
        setExpandedIndex,
        swipeHandlers,
        loadedPhotos,
        getNewPage,
    } = useSessionGalleryInteractions(
        String(photoSessionId),
        Number(galleryId),
    );

    const [showShareModal, setShowShareModal] = useState(false);
    const [shareIdentifiers, setShareIdentifiers] = useState<string[]>([]);
    const onShareButtonClick = useCallback((shareIdentifier: string) => {
        setShareIdentifiers([shareIdentifier]);
        setTimeout(() => {
            setShowShareModal(true);
        }, 50);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line
    const onAddToMyGallery = useCallback(() => {
        setShareIdentifiers(photos.map((photo) => photo.shareIdentifier));
        setTimeout(() => {
            setShowShareModal(true);
        }, 50);
    }, [photos]);

    const context = useMemo(
        () => ({
            setShowShareModal: onShareButtonClick,
            setExpandedIndex,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (!gallery && !isLoading) {
        return (
            <div>
                <h3>gallery not found...</h3>
            </div>
        );
    }

    return (
        <GalleryShareContext.Provider value={context}>
            <div className="Gallery-view">
                <GlobalStyle
                    foregroundColor={gallery?.foregroundColor}
                    backgroundColor={gallery?.backgroundColor}
                    headerBackgroundColor={gallery?.headerBackgroundColor}
                    headerForegroundColor={gallery?.headerForegroundColor}
                />
                {useV2 ? (
                    <AbsoluteGallery
                        gallery={gallery}
                        isLoading={isLoading}
                        photos={photos}
                    />
                ) : (
                    <FeatureFlag
                        flag={FeatureFlags.GALLERY_V2}
                        fallback={() => {
                            return (
                                <>
                                    <GalleryView
                                        {...{
                                            gallery,
                                            isLoading,
                                            photos,
                                            delayed,
                                            expandedIndex,
                                            setExpandedIndex,
                                            swipeHandlers,
                                            loadedPhotos,
                                            getNewPage,
                                            galleryId: gallery?.id || 0,
                                        }}
                                    />

                                    {gallery && showShareModal && (
                                        <ShareModal
                                            {...{
                                                gallery,
                                                shareIdentifiers,
                                                setShowShareModal,
                                            }}
                                        />
                                    )}
                                </>
                            );
                        }}
                    >
                        <AbsoluteGallery
                            gallery={gallery}
                            isLoading={isLoading}
                            photos={photos}
                        />
                    </FeatureFlag>
                )}
            </div>
        </GalleryShareContext.Provider>
    );
}
