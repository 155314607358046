import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useRerender from '../../Common/components/hooks/useRerender';
import Table, { createColumnHelper } from '../../Common/components/Table';
import FeatureFlagActions from './FeatureFlagActions';
import useFeatureFlagManagment from './hooks/useFeatureFlagManagement';
import RoleFeatureFlagAssociationActions from './RoleFeatureFlagAssociationActions';
import { Button } from '@/src/shadComponents/ui/button';
import PageWithTable from '@/src/Common/components/PageWithTable';
import { X } from 'lucide-react';
import { Check } from 'lucide-react';
import LoadingIndicator from '@/src/Common/components/LoadingIndicator';
import { toast } from 'sonner';

type FeatureFlag = {
    id: number;
    key: string;
    enabled: boolean;
    enabledForDevelopment: boolean;
};

/*interface FeatureFlagRoleAssociation {
    accountRoleId: number;
    featureFlagId: number;
    name: string;
    key: string;
}*/

interface FeatureFlagRoleAssociationMap {
    name: string;
    keys: string[];
}

const featureFlagColumnHelper = createColumnHelper<FeatureFlag>();
const associationColumnHelper =
    createColumnHelper<FeatureFlagRoleAssociationMap>();

export default function FeatureFlags() {
    const { render, renderCount } = useRerender();

    const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);
    const [featureFlagRoleAssociations, setFeatureFlagRoleAssociations] =
        useState<FeatureFlagRoleAssociationMap[]>([]);

    const {
        isLoading,
        fetchFeatureFlags,
        setIsLoading,
        getFeatureFlagsForRoles,
    } = useFeatureFlagManagment();

    const onDelete = () => {
        toast.success('Feature flag deleted');
        render();
    };

    const featureFlagColumnDefinitions = [
        featureFlagColumnHelper.accessor('key', {
            id: 'key',
            header: () => <span>key</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        featureFlagColumnHelper.accessor('enabled', {
            id: 'enabled',
            header: () => <span>enabled</span>,
            cell: (info) => <span>{info.getValue() ? <Check /> : <X />}</span>,
        }),
        featureFlagColumnHelper.accessor('enabledForDevelopment', {
            id: 'enabledForDevelopment',
            header: () => <span>dev enabled</span>,
            cell: (info) => <span>{info.getValue() ? <Check /> : <X />}</span>,
        }),
        featureFlagColumnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <FeatureFlagActions onDelete={onDelete} row={props.row} />
            ),
        }),
    ];

    const roleAssociationColumnDefinitions = [
        associationColumnHelper.accessor('name', {
            id: 'name',
            header: () => <span>role</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        associationColumnHelper.accessor('keys', {
            id: 'keys',
            header: () => <span>features</span>,
            cell: (info) => <span>{info.getValue().length} feature flags</span>,
        }),
        associationColumnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <RoleFeatureFlagAssociationActions row={props.row} />
            ),
        }),
    ];

    useEffect(() => {
        let isMounted = true;
        const getFeatureFlags = async () => {
            try {
                const flags = await fetchFeatureFlags();
                const associations = await getFeatureFlagsForRoles();

                setFeatureFlagRoleAssociations(associations);
                setFeatureFlags(flags);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getFeatureFlags();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderCount]);

    console.log(`Render count: ${renderCount}`);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const actions = (
        <div className="flex justify-end">
            <NavLink to="create">
                <Button className="Primary-button"> + Create Flag</Button>
            </NavLink>
        </div>
    );

    return (
        <PageWithTable
            title="Feature Flags"
            actions={actions}
            subtitle="Feature flags are used to manage which features are seen by users. Tread carefully!"
        >
            <Table data={featureFlags} columns={featureFlagColumnDefinitions} />
            <h3 className="text-lg font-semibold leading-6 text-gray-900 pl-7 pt-8 pb-2">
                Role Associations
            </h3>
            <p className="text-sm text-gray-500 pl-7 pb-4">
                Feature flags can be assigned to specific roles.
            </p>
            <Table
                data={featureFlagRoleAssociations}
                columns={roleAssociationColumnDefinitions}
            />
            <br />
            <br />
            <br />
        </PageWithTable>
    );
}
