import React, { useEffect, useState } from 'react';
import '../../Common/components/Form.css';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import useLicenseTypeManagement from './hooks/useLicenseTypeManagement';
import { useNavigate, useParams } from 'react-router';
import Loading from '../../Common/components/Loading';
import { Button } from '@/src/shadComponents/ui/button';
import Table, { createColumnHelper } from '../../Common/components/Table';
import * as Yup from 'yup';
import { toast } from 'sonner';
import useFeatureFlagManagment from '@/src/Features/components/hooks/useFeatureFlagManagement';

interface LicenseType {
    id: number;
    name: string;
    key: string;
    description: string | null;
    adminName: string;
    simultaneousDeviceCount: number;
}

interface LicenseTypeFeature {
    id: number;
    licenseTypeId: number;
    featureId: number;
    key: string;
}

interface Feature {
    id: number;
    key: string;
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required('Name is required')
        .min(3, 'Name must be at least 3 characters')
        .max(50, 'Name must be less than 50 characters'),
    description: Yup.string()
        .required('Description is required')
        .max(500, 'Description must be less than 500 characters'),
    adminName: Yup.string()
        .required('Admin name is required')
        .min(3, 'Admin name must be at least 3 characters')
        .max(50, 'Admin name must be less than 50 characters'),
    simultaneousDeviceCount: Yup.number()
        .required('Device limit is required')
        .min(1, 'Device limit must be at least 1')
        .max(1000, 'Device limit must be less than 1000'),
});

const featureColumnHelper = createColumnHelper<Feature>();

export default function EditLicenseType() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [licenseType, setLicenseType] = useState<LicenseType | null>(null);
    const [features, setFeatures] = useState<Feature[]>([]);
    const [selectedFeatures, setSelectedFeatures] = useState<
        LicenseTypeFeature[]
    >([]);
    const {
        getLicenseType,
        updateLicenseType,
        listFeaturesForLicenseType,
        addFeatureToLicenseType,
        removeFeatureFromLicenseType,
        isLoading,
        setIsLoading,
    } = useLicenseTypeManagement();

    const { fetchFeatureFlags } = useFeatureFlagManagment();

    const featureColumnDefinitions = [
        featureColumnHelper.accessor('key', {
            id: 'key',
            header: () => <span>Feature</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        featureColumnHelper.display({
            id: 'actions',
            cell: (props) => {
                const feature = props.row.original;
                const isSelected = selectedFeatures.some(
                    (f) => f.featureId === feature.id,
                );

                return (
                    <Button
                        variant={isSelected ? 'destructive' : 'outline'}
                        size="sm"
                        onClick={() => toggleFeature(feature)}
                    >
                        {isSelected ? 'Remove' : 'Add'}
                    </Button>
                );
            },
        }),
    ];

    const toggleFeature = async (feature: Feature) => {
        if (!licenseType) return;

        const isSelected = selectedFeatures.some(
            (f) => f.featureId === feature.id,
        );

        try {
            if (isSelected) {
                await removeFeatureFromLicenseType(licenseType.id, feature.id);
                setSelectedFeatures(
                    selectedFeatures.filter((f) => f.featureId !== feature.id),
                );
                toast.success(`Removed feature: ${feature.key}`);
            } else {
                const newFeature = await addFeatureToLicenseType(
                    licenseType.id,
                    feature.id,
                );
                setSelectedFeatures([...selectedFeatures, newFeature]);
                toast.success(`Added feature: ${feature.key}`);
            }
        } catch (error) {
            console.error('Failed to toggle feature:', error);
            if (error instanceof Error) {
                toast.error(error.message || 'Failed to toggle feature');
            }
        }
    };

    useEffect(() => {
        let isMounted = true;

        const load = async () => {
            if (!id) return;

            try {
                const [licenseType, features, featureFlags] = await Promise.all(
                    [
                        getLicenseType(parseInt(id)),
                        listFeaturesForLicenseType(parseInt(id)),
                        fetchFeatureFlags(),
                    ],
                );

                if (isMounted) {
                    setLicenseType(licenseType);
                    setSelectedFeatures(features);
                    setFeatures(featureFlags);
                }
            } catch (error) {
                console.error('Failed to load license type:', error);
                if (error instanceof Error) {
                    toast.error(error.message || 'Failed to load license type');
                }
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    if (!licenseType) {
        return <div>License type not found</div>;
    }

    return (
        <div className="">
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">Edit</span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Edit License Type
                                </div>
                                <div className="mt-1 text-sm leading-6 font-light text-gray-500">
                                    Modify license type details and manage
                                    features.
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>

            <div className="space-y-10">
                <Formik
                    initialValues={licenseType}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            await updateLicenseType(licenseType.id, values);
                            toast.success('License type updated successfully');
                            navigate(-1);
                        } catch (error) {
                            console.error(
                                'Failed to update license type:',
                                error,
                            );
                            if (error instanceof Error) {
                                toast.error(
                                    error.message ||
                                        'Failed to update license type',
                                );
                            }
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ isSubmitting, errors, touched }) => (
                        <FormikForm
                            className="mt-6"
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                        >
                            <div className="space-y-12">
                                <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
                                    <div className="px-8">
                                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                                            License Type Details
                                        </h2>
                                        <p className="mt-1 text-sm leading-6 text-gray-600">
                                            Update the properties of this
                                            license type.
                                        </p>
                                    </div>
                                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 p-4 sm:p-0">
                                        <div className="sm:col-span-4">
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Name
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                    <Field
                                                        id="name"
                                                        name="name"
                                                        className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                            errors.name &&
                                                            touched.name
                                                                ? 'border-red-500'
                                                                : ''
                                                        }`}
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    name="name"
                                                    component="div"
                                                    className="mt-1 text-sm text-red-600"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-full">
                                            <label
                                                htmlFor="description"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Description
                                            </label>
                                            <div className="mt-2">
                                                <Field
                                                    as="textarea"
                                                    id="description"
                                                    name="description"
                                                    rows={3}
                                                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                                                        errors.description &&
                                                        touched.description
                                                            ? 'border-red-500'
                                                            : ''
                                                    }`}
                                                />
                                                <ErrorMessage
                                                    name="description"
                                                    component="div"
                                                    className="mt-1 text-sm text-red-600"
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-4">
                                            <label
                                                htmlFor="adminName"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Admin Name
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                    <Field
                                                        id="adminName"
                                                        name="adminName"
                                                        className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                            errors.adminName &&
                                                            touched.adminName
                                                                ? 'border-red-500'
                                                                : ''
                                                        }`}
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    name="adminName"
                                                    component="div"
                                                    className="mt-1 text-sm text-red-600"
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-4">
                                            <label
                                                htmlFor="simultaneousDeviceCount"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Device Limit
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                    <Field
                                                        type="number"
                                                        id="simultaneousDeviceCount"
                                                        name="simultaneousDeviceCount"
                                                        min="1"
                                                        className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                            errors.simultaneousDeviceCount &&
                                                            touched.simultaneousDeviceCount
                                                                ? 'border-red-500'
                                                                : ''
                                                        }`}
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    name="simultaneousDeviceCount"
                                                    component="div"
                                                    className="mt-1 text-sm text-red-600"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                                <button
                                    type="button"
                                    className="text-sm font-semibold leading-6 text-gray-900"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                                >
                                    {isSubmitting ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </FormikForm>
                    )}
                </Formik>

                <div className="px-8 pb-16">
                    <h3 className="text-lg font-semibold leading-6 text-gray-900 pb-2">
                        Features
                    </h3>
                    <p className="text-sm text-gray-500 pb-4">
                        Manage which features are available with this license
                        type.
                    </p>
                    <Table data={features} columns={featureColumnDefinitions} />
                </div>
            </div>
        </div>
    );
}
