import { useRef, useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import { useFormik } from 'formik';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';
import Modal from '@/src/Common/components/Modal';
import { PencilIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';

export default function ReplaceMedia({ mediaId, onComplete, onCancel }: any) {
    const [isLoading, setIsLoading] = useState(false);
    const cancelButtonRef = useRef(null);

    const { put } = useAuthenticatedApi();

    const replaceMedia = async (values: { [key: string]: string }) => {
        const formData = new FormData();
        for (let value in values) {
            formData.append(value, values[value]);
        }

        const response = await put(`/media/replace/${mediaId}`, formData);

        const { data } = response;

        return data;
    };

    const formik = useFormik({
        initialValues: {
            photo: '',
        },
        onSubmit: async (...args) => {
            setIsLoading(true);
            // @ts-ignore
            const response = await replaceMedia(...args);
            console.log('ReplaceMedia response', response);
            onComplete(response.originalUri);
            setIsLoading(false);
        },
    });

    return (
        <Modal cancelButtonRef={cancelButtonRef} onDismiss={onCancel}>
            <div>
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-black sm:mx-0 sm:h-10 sm:w-10">
                            <PencilIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900"
                            >
                                Replace Photo
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Replace the photo with a new one. This will
                                    not notify the user that the photo has been
                                    replaced, but it will replace the photo in
                                    the gallery.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ml-12 mt-2 p-2">
                    <div>
                        <div className="mt-2">
                            <form
                                className="Form test-form"
                                onSubmit={formik.handleSubmit}
                                encType="multpart/form-data"
                            >
                                <input
                                    type="file"
                                    name="photo"
                                    accept="*/*"
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'photo',
                                            e.currentTarget.files?.[0],
                                        );
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    type="button"
                    className="mt-3 inline-flex w-full ml-4 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => onCancel()}
                    ref={cancelButtonRef}
                >
                    Cancel
                </button>
                <br />
                <button
                    onClick={() => formik.handleSubmit()}
                    type="submit"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-800 hover:bg-black-50 sm:mt-0 sm:w-auto"
                >
                    Replace
                    {isLoading ? <LoadingIndicator /> : null}
                </button>
            </div>
        </Modal>
    );
}
