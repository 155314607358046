import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { toast } from 'sonner';

export default function CopyToClipboard({ text }: { text: string }) {
    return (
        <button
            onClick={() => {
                navigator.clipboard.writeText(text);
                toast.success('Copied to clipboard');
            }}
            className="text-gray-500 hover:text-gray-900 ml-2 cursor-pointer"
        >
            <ClipboardDocumentIcon className="w-6 h-6" />
        </button>
    );
}
