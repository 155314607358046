import { Fragment, useEffect, useMemo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
    EllipsisVerticalIcon,
    KeyIcon,
    UserIcon,
} from '@heroicons/react/20/solid';
import Loading from '@/src/Common/components/Loading';
import { useParams } from 'react-router-dom';
import useOrgs from './hooks/useOrgs';
import OrgAccounts from './OrgAccounts';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';
import { DashboardIcon } from '@radix-ui/react-icons';
import { Tabs, TabsContent } from '@/src/shadComponents/ui/tabs';
import PageWithSidebar from '@/src/Common/components/PageWithSidebar';
import OrgLicenses from '@/src/Licenses/components/OrgLicenses';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export default function OrgAdministration() {
    const [org, setOrg] = useState<any>(null);

    const [isLoading, setIsLoading] = useState(true);
    const { getOrg } = useOrgs();
    const { orgId } = useParams();
    const { setOrg: setAccountOrg, orgId: currentOrgId } = useUserOrg();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                // @ts-ignore
                const org = await getOrg(+orgId || 0);

                setOrg(org);

                setTimeout(() => isMounted && setIsLoading(false), 0);
            } catch (error) {
                console.error(error);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let splitPath = window.location.href.split('#');
    let selectedTab = splitPath.length > 1 ? splitPath[1] : 'overview';

    const navigation = useMemo(() => {
        var nav = [
            {
                name: 'Overview',
                href: '#overview',
                current: selectedTab === 'overview',
                icon: DashboardIcon,
            },
            {
                name: 'Accounts',
                href: '#accounts',
                current: selectedTab === 'accounts',
                icon: UserIcon,
            },
            {
                name: 'Licenses',
                href: '#licenses',
                current: selectedTab === 'licenses',
                icon: KeyIcon,
            },
        ];

        return nav;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    if (!org) {
        return <div>Org not found</div>;
    }

    return (
        <PageWithSidebar
            title={org.name}
            subtitle={org.id === currentOrgId ? 'Current Org' : ''}
            navigation={navigation}
        >
            <Tabs value={`${selectedTab}`}>
                <TabsContent value="overview">
                    <main>
                        <header className="relative isolate pt-0">
                            <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                                <div className=" flex items-center justify-between gap-x-8 mx-2 max-w-none">
                                    <div className="flex items-center gap-x-6"></div>

                                    <div className="flex items-center gap-x-4 sm:gap-x-6">
                                        {org.id !== currentOrgId && (
                                            <button
                                                onClick={() => {
                                                    setAccountOrg(org);
                                                }}
                                                className="hidden text-sm font-semibold leading-6 sm:block bg-black px-3 py-2 text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                                            >
                                                Switch To Org
                                                <i className="bi bi-shield-lock pl-2" />
                                            </button>
                                        )}
                                        <Menu
                                            as="div"
                                            className="relative sm:hidden"
                                        >
                                            <Menu.Button className="-m-3 block p-3">
                                                <span className="sr-only">
                                                    More
                                                </span>
                                                <EllipsisVerticalIcon
                                                    className="h-5 w-5 text-gray-500"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className={classNames(
                                                                    active
                                                                        ? 'bg-gray-50'
                                                                        : '',
                                                                    'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                                )}
                                                            >
                                                                Edit Details
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </header>
                    </main>
                </TabsContent>
                <TabsContent value="accounts">
                    <OrgAccounts />
                </TabsContent>
                <TabsContent value="licenses">
                    <OrgLicenses />
                </TabsContent>
            </Tabs>
        </PageWithSidebar>
    );
}
