/* eslint-disable jsx-a11y/anchor-is-valid */
import { Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useJobHelpers from './hooks/useJobHelpers';

const statuses: any = {
    Ready: 'text-green-700 bg-green-50 ring-green-600/20',
    Okay: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    'Needs Artwork': 'text-red-700 bg-red-50 ring-red-600/10',
    'Needs Configuration': 'text-red-700 bg-red-50 ring-red-600/10',
};

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

interface ActivationsListProps {
    jobId: number;
}

export default function ActivationsList({ jobId }: ActivationsListProps) {
    const navigate = useNavigate();
    const [activations, setActivations] = useState<any[]>([]);
    const { getJobActivations } = useJobHelpers();

    useEffect(() => {
        const getData = async () => {
            const activations = await getJobActivations(jobId);
            console.log(activations);
            setActivations(activations);
        };

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="">
            <tr className="text-sm leading-6 text-gray-900">
                <th
                    scope="colgroup"
                    colSpan={3}
                    className="relative isolate py-2 font-semibold"
                >
                    <div className="px-4 sm:px-8 w-max relative ">
                        Activations
                    </div>
                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('activation/create');
                            }}
                            className="mr-2 my-[6px] px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-100 rounded-sm absolute right-0"
                        >
                            <Plus className="h-5 w-5 text-black" />
                        </a>
                    </div>
                </th>
            </tr>
            <div>
                <div className="overflow-hidden border-t border-gray-100">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <table className="w-full text-left">
                                <thead className="sr-only">
                                    <tr>
                                        <th>Activation Name</th>
                                        <th className="hidden sm:table-cell">
                                            Location
                                        </th>
                                        <th>More details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activations.map((activation) => (
                                        <tr
                                            key={activation.id}
                                            className="even:bg-gray-50"
                                        >
                                            <td className="relative py-5 pr-6">
                                                <div className="flex gap-x-6">
                                                    {/*<transaction.icon
                                                                    className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                                                    aria-hidden="true"
                                                />*/}
                                                    <div className="flex-auto">
                                                        <div className="flex items-start gap-x-3">
                                                            <div className="text-sm font-medium leading-6 text-gray-900">
                                                                {
                                                                    activation.name
                                                                }
                                                            </div>
                                                            {activation.status ? (
                                                                <div
                                                                    className={classNames(
                                                                        statuses[
                                                                            activation
                                                                                .status
                                                                        ],
                                                                        'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset',
                                                                    )}
                                                                >
                                                                    {
                                                                        activation.status
                                                                    }
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className="mt-1 text-xs leading-5 text-gray-500">
                                                            {activation.type}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                            </td>
                                            <td className="hidden py-5 pr-6 sm:table-cell">
                                                <div className="text-sm leading-6 text-gray-900">
                                                    {activation.location}
                                                </div>
                                                <div className="mt-1 text-xs leading-5 text-gray-500">
                                                    {activation.description}
                                                </div>
                                            </td>
                                            <td className="py-5 text-right">
                                                <div className="flex justify-end">
                                                    <a
                                                        href={`/activations/${activation.id}`}
                                                        className="text-sm font-medium leading-6 text-black hover:text-indigo-500"
                                                    >
                                                        View
                                                        <span className="hidden sm:inline">
                                                            {' '}
                                                            Activation &rarr;
                                                        </span>
                                                    </a>
                                                </div>
                                                {activation.staff ? (
                                                    <div className="mt-1 text-xs leading-5 text-gray-500">
                                                        Owner{' '}
                                                        <span className="text-gray-900">
                                                            {
                                                                activation.staff.filter(
                                                                    (
                                                                        staff: any,
                                                                    ) =>
                                                                        staff.role ===
                                                                        'Production Manager',
                                                                )[0].name
                                                            }
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
