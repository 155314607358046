import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useRerender from '../../Common/components/hooks/useRerender';
import Table, { createColumnHelper } from '../../Common/components/Table';
import DeviceKeyActions from './DeviceKeyActions';
import useDeviceKeys from './hooks/useDeviceKeys';
import moment from 'moment-timezone';
import useUserOrg from '../../Authentication/components/hooks/useUserOrg';
import { Button } from '@/src/shadComponents/ui/button';
import Loading from '@/src/Common/components/Loading';

const columnHelper = createColumnHelper<DeviceKey>();

export default function DeviceKeys() {
    const { orgId } = useUserOrg();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [deviceKeys, setDeviceKeys] = useState<DeviceKey[]>([]);
    const { getAssignableDevices } = useDeviceKeys(orgId);

    const { render, renderCount } = useRerender();

    const onDelete = () => {
        render();
    };

    useEffect(() => {
        let isMounted = true;
        const load = async () => {
            try {
                const deviceKeys = await getAssignableDevices();
                setDeviceKeys(deviceKeys);
            } catch (e) {
                console.error(e);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [renderCount]);

    const columnDefinitions = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => <span>name</span>,
            cell: (info) => (
                <span>
                    <NavLink
                        to={`/orgs/${orgId}/device-telemetry/${info.row.original.keyId}`}
                    >
                        {info.getValue()}
                    </NavLink>
                </span>
            ),
        }),
        columnHelper.accessor('keyId', {
            id: 'keyId',
            header: () => <span>keyId</span>,
            cell: (info) => (
                <span>
                    <NavLink
                        to={`/orgs/${orgId}/device-telemetry/${info.getValue()}`}
                    >
                        {info.getValue()}
                    </NavLink>
                </span>
            ),
        }),
        columnHelper.accessor('lastUsed', {
            id: 'lastUsed',
            header: () => <span>last seen</span>,
            cell: (info) => (
                <span>
                    {info.getValue()
                        ? `${moment(info.getValue()).toNow(true)} ago`
                        : ''}
                </span>
            ),
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <DeviceKeyActions
                    orgId={orgId}
                    row={props.row}
                    onDelete={onDelete}
                />
            ),
        }),
    ];

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    return (
        <div>
            <div className="row flex mx-4">
                <div className="spacer"></div>
                <NavLink to="device-keys/create">
                    <Button className="Primary-button">
                        {' '}
                        + Create Device Key
                    </Button>
                </NavLink>
            </div>
            <br />
            <Table data={deviceKeys} columns={columnDefinitions} />
        </div>
    );
}
