import ShareSheet from './ShareSheet';

interface DownloadButtonProps {
    uri: string;
    mimeType: string;
    mediaId: number;
    source: 'gallery' | 'personal-gallery' | 'share';
}
export default function DownloadButton({
    uri,
    mimeType,
    mediaId,
    source,
}: DownloadButtonProps) {
    return (
        <ShareSheet
            uri={uri}
            mimeType={mimeType}
            mediaId={mediaId}
            source={source}
        >
            <i className="bi bi-arrow-down"></i>
        </ShareSheet>
    );
}
