import { useState } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

interface LicenseType {
    id: number;
    name: string;
    key: string;
    description: string | null;
    adminName: string;
    simultaneousDeviceCount: number;
}

interface LicenseTypeFeature {
    id: number;
    licenseTypeId: number;
    featureId: number;
    key: string;
}

interface License {
    id: number;
    key: string;
    licenseTypeId: number;
    orgId: number;
    orgName: string;
    licenseTypeName: string;
    expiresAt: string | null;
    createdAt: string;
    expiredAt: string | null;
}

interface CreateLicenseInput {
    orgId: number;
    licenseTypeId: number;
    expiresAt: string | null;
}

export default function useLicenseTypeManagement() {
    const authenticatedApi = useAuthenticatedApi();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // License Type Management
    const fetchLicenseTypes = async (): Promise<LicenseType[]> => {
        const response = await authenticatedApi.get('/licenses/types');
        return response.data;
    };

    const getLicenseType = async (id: number): Promise<LicenseType> => {
        const response = await authenticatedApi.get(`/licenses/types/${id}`);
        return response.data;
    };

    const createLicenseType = async (values: {
        name: string;
        description: string | null;
        adminName: string;
        simultaneousDeviceCount: number;
    }): Promise<LicenseType> => {
        const response = await authenticatedApi.post(
            '/licenses/types',
            JSON.stringify(values),
        );
        return response.data;
    };

    const updateLicenseType = async (
        id: number,
        values: {
            name: string;
            description: string | null;
            adminName: string;
            simultaneousDeviceCount: number;
        },
    ): Promise<LicenseType> => {
        const response = await authenticatedApi.put(
            `/licenses/types/${id}`,
            JSON.stringify(values),
        );
        return response.data;
    };

    const deleteLicenseType = async (id: number): Promise<void> => {
        await authenticatedApi.delete(`/licenses/types/${id}`);
    };

    // License Type Feature Management
    const listFeaturesForLicenseType = async (
        licenseTypeId: number,
    ): Promise<LicenseTypeFeature[]> => {
        const response = await authenticatedApi.get(
            `/licenses/types/${licenseTypeId}/features`,
        );
        return response.data;
    };

    const addFeatureToLicenseType = async (
        licenseTypeId: number,
        featureId: number,
    ): Promise<LicenseTypeFeature> => {
        const response = await authenticatedApi.post(
            `/licenses/types/${licenseTypeId}/features`,
            JSON.stringify({ featureId }),
        );
        return response.data;
    };

    const removeFeatureFromLicenseType = async (
        licenseTypeId: number,
        featureId: number,
    ): Promise<void> => {
        await authenticatedApi.delete(
            `/licenses/types/${licenseTypeId}/features/${featureId}`,
        );
    };

    // License Management
    const listLicenses = async (): Promise<License[]> => {
        const response = await authenticatedApi.get('/licenses');
        return response.data;
    };

    const getLicense = async (id: number): Promise<License> => {
        const response = await authenticatedApi.get(`/licenses/${id}`);
        return response.data;
    };

    const getLicenseByKey = async (key: string): Promise<License> => {
        const response = await authenticatedApi.get(`/licenses/key/${key}`);
        return response.data;
    };

    const getLicensesForOrg = async (orgId: number): Promise<License[]> => {
        const response = await authenticatedApi.get(`/licenses/org/${orgId}`);
        return response.data;
    };

    const createLicense = async (
        values: CreateLicenseInput,
    ): Promise<License> => {
        const response = await authenticatedApi.post(
            '/licenses',
            JSON.stringify(values),
        );
        return response.data;
    };

    const expireLicense = async (id: number): Promise<void> => {
        await authenticatedApi.delete(`/licenses/${id}`);
    };

    return {
        isLoading,
        setIsLoading,
        // License Type Management
        fetchLicenseTypes,
        getLicenseType,
        createLicenseType,
        updateLicenseType,
        deleteLicenseType,
        // License Type Feature Management
        listFeaturesForLicenseType,
        addFeatureToLicenseType,
        removeFeatureFromLicenseType,
        // License Management
        listLicenses,
        getLicense,
        getLicenseByKey,
        getLicensesForOrg,
        createLicense,
        expireLicense,
    };
}
