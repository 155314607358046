import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';

export default function PaymentForm({
    clientSecret,
    setError,
    onSuccess,
}: {
    clientSecret: string;
    setError: (error: string) => void;
    onSuccess: (paymentIntent: any) => void;
}) {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        try {
            await elements.submit();
            const result = await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: window.location.href,
                },
                redirect: 'if_required',
            });

            if (result.error) {
                setError(result.error.message ?? 'Payment failed');
            } else {
                onSuccess(result.paymentIntent);
            }
        } catch (error) {
            setError('Payment failed');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button
                type="submit"
                className="w-full rounded-[4px] py-[12px] mt-[24px] font-bold text-center Button-primary cursor-pointer"
            >
                Pay
            </button>
        </form>
    );
}
