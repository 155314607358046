import {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
    useContext,
    ReactNode,
} from 'react';
import GalleryContext from '@/src/Gallery/components/GalleryContext';
import logo from './white_logo.png';
import Loading from '@/src/Common/components/Loading';
import GalleryLightbox from './GalleryLightbox';
import useGalleryBehaviors from './hooks/useGalleryBehvaiors';
import useQueryString from '@/src/Common/components/hooks/useQueryString';
import { useSearchParams } from 'react-router-dom';
import GalleryShareContext from './GalleryShareContext';
import DownloadButtonV2 from '@/src/Share/components/DownloadButtonV2';
import React from 'react';

interface AbsoluteGalleryProps {
    gallery: GalleryObject | null;
    isLoading?: boolean;
    photos: MediaInstance[];
    message?: ReactNode;
}

let columnHeights: number[] = [];

export default function AbsoluteGallery({
    gallery,
    isLoading = false,
    photos,
    message,
}: AbsoluteGalleryProps) {
    const [columns, setColumns] = useState<number>(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const GAP = 0; // Gap between images in pixels
    const [columnWidth, setColumnWidth] = useState<number>(0);
    const hasCTA = gallery?.ctaImage || gallery?.ctaText;
    const query = useQueryString();
    const urlShareIdentifier = query.get('sid');
    const [shareIdentifierToIndexLookup, setShareIdentifierToIndexLookup] =
        useState<{ [key: string]: number }>({});

    // Calculate number of columns based on container width
    useEffect(() => {
        const updateColumns = () => {
            const width = containerRef.current?.offsetWidth ?? 0;
            if (width < 1200) {
                setColumns(2);
                setColumnWidth(width / 2);
            } else if (width < 1800) {
                setColumns(3);
                setColumnWidth(width / 3);
            } else {
                setColumns(4);
                setColumnWidth(width / 4);
            }
        };

        updateColumns();
        window.addEventListener('resize', updateColumns);
        return () => window.removeEventListener('resize', updateColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Calculate positions for each photo
    const getPhotoPositions = useMemo(() => {
        let photoCount = photos.length;
        columnHeights = new Array(columns).fill(0);
        return photos.map((photo, index) => {
            // @ts-ignore
            const aspectRatio =
                // @ts-ignore
                photo.aspectRatio || 1.5;
            const width = `${columnWidth}px`;
            const shortestColumn = columnHeights.indexOf(
                Math.min(...columnHeights),
            );
            const x = `${
                shortestColumn * (100 / columns) + shortestColumn * GAP
            }%`;
            const y = columnHeights[shortestColumn];

            const photoHeight = columnWidth / aspectRatio;
            columnHeights[shortestColumn] += photoHeight + GAP;

            if (photoCount === 1) {
                const containerWidth = containerRef.current?.offsetWidth ?? 500;
                const width =
                    containerWidth - (containerWidth > 800 ? 300 : 100);
                const height = width / aspectRatio;
                columnHeights[shortestColumn] = height + GAP;
                return {
                    id: photo.id,
                    style: {
                        position: 'absolute',
                        left: `calc(50% - ${width / 2}px)`,
                        top: 0,
                        width: `${width}px`,
                        height: `${height}px`,
                        display: 'flex',
                    },
                    shareIdentifier: photo.shareIdentifier,
                    thumbnail: photo.thumbnail_800pxUri,
                    full: photo.originalUri,
                    tiny: photo.thumbnailTinyUri,
                    mimeType: photo.mimeType,
                    imageStyle: {
                        objectFit: 'contain',
                        backgroundSize: 'cover',
                        backgroundColor: 'rgba(0,0,0,0.03)',
                        transition: 'all 0.3s linear',
                        margin: 4,
                        flex: 1,
                    },
                };
            }

            return {
                id: photo.id,
                style: {
                    position: 'absolute',
                    left: x,
                    top: y,
                    width,
                    height: `${photoHeight}px`,
                    display: 'flex',
                },
                shareIdentifier: photo.shareIdentifier,
                thumbnail: photo.thumbnail_800pxUri,
                full: photo.originalUri,
                tiny: photo.thumbnailTinyUri,
                mimeType: photo.mimeType,
                imageStyle: {
                    objectFit: 'contain',
                    backgroundSize: 'cover',
                    backgroundColor: 'rgba(0,0,0,0.03)',
                    transition: 'all 0.3s linear',
                    margin: 4,
                    flex: 1,
                },
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photos, columns, columnWidth]);

    const [shareIdentifier, setShareIdentifier] = useState<string | null>(
        urlShareIdentifier ?? null,
    );
    const [photoIndex, setPhotoIndex] = useState<number>(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentSearchParams = Object.fromEntries(
        Array.from(searchParams.entries()).filter(
            ([key]: [string, string]) => key !== 'sid',
        ),
    );

    useEffect(() => {
        let lookup: { [key: string]: number } = {};
        photos.forEach((photo, index) => {
            lookup[photo.shareIdentifier] = index;
        });

        setShareIdentifierToIndexLookup(lookup);
    }, [photos]);

    useEffect(() => {
        if (shareIdentifier) {
            setPhotoIndex(shareIdentifierToIndexLookup[shareIdentifier] ?? 0);

            setSearchParams({ ...currentSearchParams, sid: shareIdentifier });

            const element = document.getElementById(
                `${shareIdentifier}-element`,
            );
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // @ts-ignore
            if (searchParams?.size !== 0) {
                setSearchParams({
                    ...currentSearchParams,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        shareIdentifierToIndexLookup,
        shareIdentifier,
        photos,
        setPhotoIndex,
        setSearchParams,
        searchParams,
    ]);

    const { swipeHandlers } = useGalleryBehaviors(
        () => {
            setShareIdentifier(null);
        },
        () => {
            if (!shareIdentifier) {
                return;
            }

            let index = shareIdentifierToIndexLookup[shareIdentifier] ?? 0;

            if (index >= 0 && photos[index + 1]) {
                setShareIdentifier(photos[index + 1].shareIdentifier);
            } else {
                setShareIdentifier(null);
            }
        },
        () => {
            if (!shareIdentifier) {
                return;
            }

            let index = shareIdentifierToIndexLookup[shareIdentifier] ?? 0;

            if (index >= 0 && photos[index - 1]) {
                setShareIdentifier(photos[index - 1].shareIdentifier);
            } else {
                setShareIdentifier(null);
            }
        },
    );

    return (
        <GalleryContext.Provider
            value={{ shareIdentifier, setShareIdentifier }}
        >
            <Loading isLoading={isLoading} />
            {!isLoading && (
                <div
                    className={`Gallery-header ${
                        shareIdentifier ? 'hidden invisible' : ''
                    }`}
                >
                    <div className="Gallery-header-title">
                        <img
                            src={gallery?.logo || logo}
                            alt="Smilebooth Logo"
                        />
                    </div>
                    <div className="Gallery-name">{gallery?.name}</div>
                </div>
            )}
            <div
                className={`Gallery-content ${
                    hasCTA ? 'pb-[100px]' : 'pb-[20px]'
                }`}
                id="galleryContainer"
            >
                <div
                    ref={containerRef}
                    className="relative w-full"
                    style={{ height: `${Math.max(...columnHeights)}px` }}
                >
                    <GalleryLayout
                        layout={getPhotoPositions}
                        setShareIdentifier={setShareIdentifier}
                    />
                </div>
                {message ? (
                    <div className="Gallery-message">
                        <p>{message}</p>
                    </div>
                ) : null}
                {hasCTA ? (
                    <div
                        className="cta-container fixed bottom-0 left-0 right-0 flex justify-center max-h-[100px] items-center cursor-pointer clickable"
                        onClick={() => {
                            if (gallery?.ctaLink) {
                                window.open(gallery?.ctaLink, '_blank');
                            }
                        }}
                    >
                        {gallery?.ctaImage && (
                            <img
                                src={gallery?.ctaImage}
                                alt="Smilebooth CTA"
                                className="resizable"
                            />
                        )}
                        {gallery?.ctaText && <p>{gallery?.ctaText}</p>}
                    </div>
                ) : null}
            </div>
            <GalleryLightbox
                photos={photos}
                currentPhotoIndex={photoIndex}
                gestureHandlers={swipeHandlers}
            />
        </GalleryContext.Provider>
    );
}

AbsoluteGallery.whyDidYouRender = false;

const GalleryLayout = React.memo(function GalleryLayout({
    layout,
    setShareIdentifier,
}: {
    layout: any[];
    setShareIdentifier: (shareIdentifier: string) => void;
}) {
    return (
        <>
            {layout.map(
                ({
                    id,
                    style,
                    imageStyle,
                    thumbnail,
                    full,
                    tiny,
                    shareIdentifier,
                    mimeType,
                }) => (
                    // @ts-ignore
                    <div
                        key={`${shareIdentifier}-gutter`}
                        // @ts-ignore
                        style={style ?? {}}
                    >
                        <PhotoPreview
                            full={full}
                            thumbnail={thumbnail || full}
                            tiny={tiny}
                            style={imageStyle ?? {}}
                            shareIdentifier={shareIdentifier}
                            id={id}
                            mimeType={mimeType}
                            setShareIdentifier={setShareIdentifier}
                        />
                    </div>
                ),
            )}
        </>
    );
});

GalleryLayout.whyDidYouRender = false;

function PhotoPreview({
    style,
    thumbnail,
    tiny,
    shareIdentifier,
    id,
    mimeType,
    full,
    setShareIdentifier,
}: {
    style: any;
    thumbnail: string;
    tiny: string;
    shareIdentifier: string;
    id: number;
    mimeType: string;
    full: string;
    setShareIdentifier: (shareIdentifier: string) => void;
}) {
    const [uri, setUri] = useState<string>(tiny || thumbnail);
    const [, setIsLoading] = useState<boolean>(true);
    const ref = useRef<HTMLDivElement>(null);
    const visibility = useIsVisible(ref);
    const hasLoaded = useRef<boolean>(false);

    const galleryShareContext = useContext(GalleryShareContext);

    console.log(shareIdentifier);

    useEffect(() => {
        if (visibility) {
            const image = new Image();
            image.src = thumbnail;
            image.onload = () => {
                setUri(thumbnail);
                setIsLoading(false);
            };
        } else {
            setUri(tiny || thumbnail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thumbnail, visibility]);

    useEffect(() => {
        if (visibility) {
            hasLoaded.current = true;
        }
    }, [visibility]);

    const handleClick = useCallback(() => {
        setShareIdentifier(shareIdentifier);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareIdentifier]);

    if (!visibility && !hasLoaded.current) {
        return (
            <div
                ref={ref}
                key={shareIdentifier}
                style={{
                    ...style,
                    position: 'relative',
                    transition: 'all 0.3s linear',
                }}
            ></div>
        );
    }

    return (
        <div
            ref={ref}
            key={shareIdentifier}
            style={{
                ...style,
                position: 'relative',
                backgroundImage:
                    visibility || hasLoaded.current ? `url(${uri})` : 'none',
                transition: 'all 0.3s linear',
            }}
            className="bg-no-repeat group"
            id={`${shareIdentifier}-element`}
            onClick={handleClick}
        >
            <>
                {mimeType === 'video/mp4' && (
                    <div className="button-container">
                        <i
                            className="bi bi-play"
                            style={{ filter: 'drop-shadow(0px 0px 5px #222)' }}
                        />
                    </div>
                )}

                <div className="absolute bottom-0 right-0 left-0 bg-gradient-to-t from-[rgba(0,0,0,0.5)] to-transparent opacity-80 h-[60px] invisible group-hover:invisible md:group-hover:visible">
                    <div className="flex justify-start items-end h-full gap-2 flex-row text-white px-4 pb-4">
                        {galleryShareContext && (
                            <i
                                onClick={() => {
                                    galleryShareContext.setShowShareModal(
                                        shareIdentifier,
                                    );
                                }}
                                className="bi bi-send save-button"
                            ></i>
                        )}
                        <DownloadButtonV2
                            uri={full}
                            mimeType={mimeType}
                            mediaId={id}
                            source="gallery"
                        />
                    </div>
                </div>
            </>
        </div>
    );
}

PhotoPreview.whyDidYouRender = false;

export function useIsVisible(ref: React.RefObject<HTMLDivElement>) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            {
                rootMargin: '300px 0px 600px 0px',
            },
        );

        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
}
