import { useEffect, useState } from 'react';
import '../../Common/components/Form.css';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import useLicenseTypeManagement from './hooks/useLicenseTypeManagement';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../Common/components/Loading';
import * as Yup from 'yup';
import { toast } from 'sonner';
import useOrgs from '@/src/Orgs/components/hooks/useOrgs';

interface CreateLicenseFormValues {
    orgId: number;
    licenseTypeId: number;
    expiresAt: string | null;
}

interface Organization {
    id: number;
    name: string;
}

interface LicenseType {
    id: number;
    name: string;
    key: string;
    description: string | null;
    adminName: string;
    simultaneousDeviceCount: number;
}

const validationSchema = Yup.object({
    orgId: Yup.number().required('Organization is required'),
    licenseTypeId: Yup.number().required('License type is required'),
    expiresAt: Yup.string().nullable(),
});

const initialValues: CreateLicenseFormValues = {
    orgId: 0,
    licenseTypeId: 0,
    expiresAt: null,
};

export default function CreateLicense() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isLoading, createLicense, fetchLicenseTypes, setIsLoading } =
        useLicenseTypeManagement();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
    const { listOrgs } = useOrgs();

    useEffect(() => {
        let isMounted = true;

        const loadData = async () => {
            try {
                // TODO: Replace with actual API call to fetch organizations
                const orgs = await listOrgs();
                if (isMounted) setOrganizations(orgs);

                const types = await fetchLicenseTypes();
                if (isMounted) setLicenseTypes(types);
            } catch (error) {
                console.error('Failed to load data:', error);
                if (error instanceof Error) {
                    toast.error(error.message || 'Failed to load data');
                }
            } finally {
                if (isMounted) setIsLoading(false);
            }
        };

        loadData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    const orgId = searchParams.get('orgId');
    if (orgId) {
        initialValues.orgId = parseInt(orgId);
    }

    return (
        <div className="">
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        Create
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Create License
                                </div>
                                <div className="mt-1 text-sm leading-6 font-light text-gray-500">
                                    Create a new license for an organization.
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        await createLicense(values);
                        toast.success('License created successfully');
                        navigate(-1);
                    } catch (error) {
                        console.error('Failed to create license:', error);
                        if (error instanceof Error) {
                            toast.error(
                                error.message || 'Failed to create license',
                            );
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, errors, touched }) => (
                    <FormikForm
                        className="mt-6"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div className="space-y-12">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
                                <div className="px-8">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        License Details
                                    </h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Select the organization and license
                                        type.
                                    </p>
                                </div>

                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 px-8">
                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="orgId"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Organization
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    as="select"
                                                    id="orgId"
                                                    name="orgId"
                                                    className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                        errors.orgId &&
                                                        touched.orgId
                                                            ? 'border-red-500'
                                                            : ''
                                                    }`}
                                                >
                                                    <option value="">
                                                        Select an organization
                                                    </option>
                                                    {organizations.map(
                                                        (org) => (
                                                            <option
                                                                key={org.id}
                                                                value={org.id}
                                                            >
                                                                {org.name}
                                                            </option>
                                                        ),
                                                    )}
                                                </Field>
                                            </div>
                                            <ErrorMessage
                                                name="orgId"
                                                component="div"
                                                className="mt-1 text-sm text-red-600"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="licenseTypeId"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            License Type
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    as="select"
                                                    id="licenseTypeId"
                                                    name="licenseTypeId"
                                                    className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                        errors.licenseTypeId &&
                                                        touched.licenseTypeId
                                                            ? 'border-red-500'
                                                            : ''
                                                    }`}
                                                >
                                                    <option value="">
                                                        Select a license type
                                                    </option>
                                                    {licenseTypes.map(
                                                        (type) => (
                                                            <option
                                                                key={type.id}
                                                                value={type.id}
                                                            >
                                                                {type.name}
                                                            </option>
                                                        ),
                                                    )}
                                                </Field>
                                            </div>
                                            <ErrorMessage
                                                name="licenseTypeId"
                                                component="div"
                                                className="mt-1 text-sm text-red-600"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="expiresAt"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Expiration Date
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    type="date"
                                                    id="expiresAt"
                                                    name="expiresAt"
                                                    className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                        errors.expiresAt &&
                                                        touched.expiresAt
                                                            ? 'border-red-500'
                                                            : ''
                                                    }`}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="expiresAt"
                                                component="div"
                                                className="mt-1 text-sm text-red-600"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            >
                                {isSubmitting ? 'Creating...' : 'Create'}
                            </button>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
}
