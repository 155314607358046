import ShareSheetV2 from './ShareSheetV2';

interface DownloadButtonProps {
    uri: string;
    mimeType: string;
    mediaId: number;
    source: 'gallery' | 'personal-gallery' | 'share';
}
export default function DownloadButton({
    uri,
    mimeType,
    mediaId,
    source,
}: DownloadButtonProps) {
    return (
        <ShareSheetV2
            uri={uri}
            mimeType={mimeType}
            mediaId={mediaId}
            source={source}
        >
            <i className="bi bi-arrow-down active:opacity-50 clickable"></i>
        </ShareSheetV2>
    );
}
