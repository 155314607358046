import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Table, { createColumnHelper } from '../../Common/components/Table';
import PageWithTable from '@/src/Common/components/PageWithTable';
import { Button } from '@/src/shadComponents/ui/button';
import useLicenseTypeManagement from './hooks/useLicenseTypeManagement';
import { toast } from 'sonner';
import { format } from 'date-fns';
import Loading from '../../Common/components/Loading';
import useOrgs from '@/src/Orgs/components/hooks/useOrgs';

interface License {
    id: number;
    key: string;
    licenseTypeId: number;
    orgId: number;
    orgName: string;
    licenseTypeName: string;
    expiresAt: string | null;
    expiredAt: string | null;
    createdAt: string;
}

const licenseColumnHelper = createColumnHelper<License>();

export default function OrgLicenses() {
    const navigate = useNavigate();
    const { orgId } = useParams();
    const [licenses, setLicenses] = useState<License[]>([]);
    const [orgName, setOrgName] = useState<string>('');
    const { isLoading, getLicensesForOrg, expireLicense, setIsLoading } =
        useLicenseTypeManagement();

    const handleExpireLicense = async (license: License) => {
        try {
            await expireLicense(license.id);
            toast.success(`Expired license: ${license.licenseTypeName}`);
            await loadData();
        } catch (error) {
            console.error('Failed to expire license:', error);
            if (error instanceof Error) {
                toast.error(error.message || 'Failed to expire license');
            }
        }
    };

    const licenseColumnDefinitions = [
        licenseColumnHelper.accessor('licenseTypeName', {
            id: 'licenseType',
            header: () => <span>License Type</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        licenseColumnHelper.accessor('key', {
            id: 'key',
            header: () => <span>Key</span>,
            cell: (info) => <span>{info.getValue()}</span>,
        }),
        licenseColumnHelper.accessor('createdAt', {
            id: 'createdAt',
            header: () => <span>Created</span>,
            cell: (info) => (
                <span>{format(new Date(info.getValue()), 'MMM d, yyyy')}</span>
            ),
        }),
        licenseColumnHelper.accessor('expiresAt', {
            id: 'expiresAt',
            header: () => <span>Expires</span>,
            cell: (info) => {
                const expiresAt = info.getValue();
                const isExpired = expiresAt && new Date(expiresAt) < new Date();
                return expiresAt ? (
                    <span
                        className={`${
                            isExpired ? 'text-red-500' : ''
                        } flex items-center`}
                    >
                        {format(new Date(expiresAt), 'MMM d, yyyy')}
                    </span>
                ) : (
                    <span>Never</span>
                );
            },
        }),
        licenseColumnHelper.accessor('expiredAt', {
            id: 'expiredAt',
            header: () => <span>Expired</span>,
            cell: (info) => {
                const expiredAt = info.getValue();
                return expiredAt ? (
                    <span>{format(new Date(expiredAt), 'MMM d, yyyy')}</span>
                ) : (
                    <span className="text-green-500">Active</span>
                );
            },
        }),
        licenseColumnHelper.display({
            id: 'actions',
            header: () => <span>Actions</span>,
            cell: (info) => {
                const expiredAt = info.row.original.expiredAt;
                const isExpired = expiredAt && new Date(expiredAt) < new Date();
                return (
                    <div className="flex justify-start">
                        {!isExpired && (
                            <button
                                className="bg-red-500 hover:bg-red-600 transition-all duration-300 px-3 py-1 text-white rounded-md cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                                onClick={() =>
                                    handleExpireLicense(info.row.original)
                                }
                            >
                                Expire
                            </button>
                        )}
                        {isExpired && (
                            <span className="text-red-500">Expired</span>
                        )}
                    </div>
                );
            },
        }),
    ];

    const { getOrg } = useOrgs();

    const loadData = async () => {
        if (!orgId) return;

        try {
            const org = await getOrg(parseInt(orgId));
            setOrgName(org.name);

            const licenses = await getLicensesForOrg(parseInt(orgId));
            setLicenses(licenses);
        } catch (error) {
            console.error('Failed to load licenses:', error);
            if (error instanceof Error) {
                toast.error(error.message || 'Failed to load licenses');
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;

        const load = async () => {
            await loadData();
            isMounted && setIsLoading(false);
        };

        load();

        return () => {
            isMounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    const actions = (
        <div className="flex justify-end">
            <Button
                className="Primary-button"
                onClick={() =>
                    navigate(`/admin/licenses/create-license?orgId=${orgId}`)
                }
            >
                + Create License
            </Button>
        </div>
    );

    return (
        <PageWithTable
            title={`${orgName} Licenses`}
            actions={actions}
            subtitle="Manage licenses for this organization."
        >
            <Table data={licenses} columns={licenseColumnDefinitions} />
        </PageWithTable>
    );
}
