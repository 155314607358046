import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';

export default function useZipPhotos() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const createZip = async (
        shareIdentifiers: string[],
        destination: string,
        activationId: number,
    ) => {
        const response = await authenticatedApi.post(
            `/${orgId}/microservices/zip-media`,
            {
                shareIdentifiers,
                zipRequestDestination: destination,
                activationId,
            },
        );

        return response;
    };

    const getZipRequest = async (zipRequestId: string) => {
        const response = await authenticatedApi.get(
            `/${orgId}/microservices/zip-media/${zipRequestId}`,
        );
        return response;
    };

    const getZipRequestsForActivation = async (activationId: number) => {
        const response = await authenticatedApi.get(
            `/${orgId}/microservices/zip-media/activation/${activationId}`,
        );
        return response;
    };

    return { createZip, getZipRequest, getZipRequestsForActivation };
}
