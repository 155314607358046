import '../../Common/components/Form.css';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import useLicenseTypeManagement from './hooks/useLicenseTypeManagement';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { toast } from 'sonner';

interface CreateLicenseTypeFormValues {
    name: string;
    description: string;
    adminName: string;
    simultaneousDeviceCount: number;
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required('Name is required')
        .min(3, 'Name must be at least 3 characters')
        .max(50, 'Name must be less than 50 characters'),
    description: Yup.string()
        .required('Description is required')
        .max(500, 'Description must be less than 500 characters'),
    adminName: Yup.string()
        .required('Admin name is required')
        .min(3, 'Admin name must be at least 3 characters')
        .max(50, 'Admin name must be less than 50 characters'),
    simultaneousDeviceCount: Yup.number()
        .required('Device limit is required')
        .min(1, 'Device limit must be at least 1')
        .max(1000, 'Device limit must be less than 1000'),
});

export default function CreateLicenseType() {
    const initialValues: CreateLicenseTypeFormValues = {
        name: '',
        description: '',
        adminName: '',
        simultaneousDeviceCount: 1,
    };

    const navigate = useNavigate();
    const { createLicenseType } = useLicenseTypeManagement();

    return (
        <div className="">
            <header className="relative isolate pt-0">
                <div
                    className="absolute inset-0 -z-10 overflow-hidden"
                    aria-hidden="true"
                >
                    <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80"></div>
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                </div>

                <div className="mx-auto max-w-12xl px-4 py-8 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                        <div className="flex items-center gap-x-6">
                            <h1>
                                <div className="text-sm leading-6 text-gray-500">
                                    <span className="text-gray-700">
                                        Create
                                    </span>
                                </div>
                                <div className="mt-1 text-2xl font-semibold leading-6 text-gray-900">
                                    Create A License Type
                                </div>
                                <div className="mt-1 text-sm leading-6 font-light text-gray-500">
                                    Create a new license type to define
                                    available features.
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                    try {
                        await createLicenseType(values);
                        toast.success('License type created successfully');
                        navigate(-1);
                    } catch (error) {
                        console.error('Failed to create license type:', error);
                        if (error instanceof Error) {
                            toast.error(
                                error.message ||
                                    'Failed to create license type',
                            );
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, errors, touched }) => (
                    <FormikForm
                        className="mt-6"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div className="space-y-12">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
                                <div className="px-8">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                                        License Type Details
                                    </h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">
                                        Define the properties of this license
                                        type.
                                    </p>
                                </div>
                                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2 p-4 sm:p-0">
                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="name"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Name
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    id="name"
                                                    name="name"
                                                    className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                        errors.name &&
                                                        touched.name
                                                            ? 'border-red-500'
                                                            : ''
                                                    }`}
                                                    placeholder="Enterprise"
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="mt-1 text-sm text-red-600"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label
                                            htmlFor="description"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Description
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                as="textarea"
                                                id="description"
                                                name="description"
                                                rows={3}
                                                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                                                    errors.description &&
                                                    touched.description
                                                        ? 'border-red-500'
                                                        : ''
                                                }`}
                                                placeholder="Enterprise license with all features enabled"
                                            />
                                            <ErrorMessage
                                                name="description"
                                                component="div"
                                                className="mt-1 text-sm text-red-600"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="adminName"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Admin Name
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    id="adminName"
                                                    name="adminName"
                                                    className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                        errors.adminName &&
                                                        touched.adminName
                                                            ? 'border-red-500'
                                                            : ''
                                                    }`}
                                                    placeholder="Enterprise Admin"
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="adminName"
                                                component="div"
                                                className="mt-1 text-sm text-red-600"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label
                                            htmlFor="simultaneousDeviceCount"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Device Limit
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <Field
                                                    type="number"
                                                    id="simultaneousDeviceCount"
                                                    name="simultaneousDeviceCount"
                                                    min="1"
                                                    className={`block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                                                        errors.simultaneousDeviceCount &&
                                                        touched.simultaneousDeviceCount
                                                            ? 'border-red-500'
                                                            : ''
                                                    }`}
                                                />
                                            </div>
                                            <ErrorMessage
                                                name="simultaneousDeviceCount"
                                                component="div"
                                                className="mt-1 text-sm text-red-600"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-6 px-6 fixed bottom-0 left-0 right-0 z-50 border-t border-gray-900/10 bg-white p-3">
                            <button
                                type="button"
                                className="text-sm font-semibold leading-6 text-gray-900"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                            >
                                {isSubmitting ? 'Creating...' : 'Create'}
                            </button>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
}
