import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { DownloadIcon, UploadIcon } from '@radix-ui/react-icons';
import { EyeIcon, FilterIcon, LucideGalleryThumbnails } from 'lucide-react';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

const filterOptions = [
    { name: 'Photo', value: 'processed' },
    { name: 'Video', value: 'processed-video' },
    { name: 'Edited', value: 'user-edit' },
    { name: 'Prints', value: 'composite' },
    { name: 'Originals', value: 'original' },
];

export default function PhotoManagerFilters({
    photos,
    setPhotos,
    onFilterChange,
    activationId,
    setIsShowingZipPrompt,
    setIsShowingBulkUpload,
    showHidden,
    setShowHidden,
    sortOrder,
    setSortOrder,
    selectedShareIdentifiers,
    setSelectedShareIdentifiers,
    columnCount,
    setColumnCount,
}: {
    photos: MediaInstance[];
    onFilterChange: (filter: string[]) => void;
    onSortChange: (sort: 'asc' | 'desc') => void;
    activationId: number;
    isShowingZipPrompt: boolean;
    setIsShowingZipPrompt: (isShowing: boolean) => void;
    isShowingBulkUpload: boolean;
    setIsShowingBulkUpload: (isShowing: boolean) => void;
    showHidden: boolean;
    setShowHidden: (showHidden: boolean) => void;
    sortOrder: 'asc' | 'desc';
    setSortOrder: (sort: 'asc' | 'desc') => void;
    selectedShareIdentifiers: string[];
    setSelectedShareIdentifiers: (shareIdentifiers: string[]) => void;
    setPhotos: (photos: MediaInstance[]) => void;
    columnCount: number;
    setColumnCount: (columnCount: number) => void;
}) {
    const [typeFilter, setTypeFilter] = useState<string[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const authenticatedApi = useAuthenticatedApi();
    const [isReprocessing, setIsReprocessing] = useState(false);

    useEffect(() => {
        if (typeFilter.length === 0) {
            setTypeFilter([
                'processed',
                'processed-video',
                'user-edit',
                'composite',
                'original',
            ]);
        }
    }, [setTypeFilter, typeFilter]);

    useEffect(() => {
        onFilterChange(typeFilter);

        // eslint-disable-next-line
    }, [typeFilter]);

    const [isHidden, setIsHidden] = useState(false);

    return (
        <div className="px-4 sm:px-6 lg:px-8 pb-10">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto flex flex-row">
                    <h1 className="text-2xl font-semibold text-gray-900">
                        Photos ({photos.length})
                    </h1>
                    <span className="inline-title flex flex-row items-center">
                        <div className="text-sm text-gray-700 flex flex-row items-center ">
                            <div className="relative">
                                <button
                                    type="button"
                                    className="w-full bg-white border border-gray-300 flex flex-row items-center rounded-md px-4 py-2 text-left focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    <FilterIcon className="h-4 w-4 mr-2" />
                                    {typeFilter.length === 5
                                        ? 'All Types'
                                        : `Selected: ${typeFilter.length}`}
                                </button>

                                {isOpen && (
                                    <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto z-10">
                                        <div className="p-2">
                                            <div
                                                className="cursor-pointer hover:bg-gray-100 px-3 py-2 rounded"
                                                onClick={() => {
                                                    setTypeFilter([
                                                        'processed',
                                                        'processed-video',
                                                        'user-edit',
                                                        'composite',
                                                        'original',
                                                    ]);
                                                    setIsOpen(false);
                                                }}
                                            >
                                                All Types
                                            </div>
                                            {filterOptions.map((option) => (
                                                <div
                                                    key={option.value}
                                                    className={`cursor-pointer hover:bg-gray-100 px-3 py-2 rounded flex items-center ${
                                                        typeFilter.length === 5
                                                            ? 'opacity-50 cursor-not-allowed'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        setTypeFilter((prev) =>
                                                            prev.includes(
                                                                option.value,
                                                            )
                                                                ? prev.filter(
                                                                      (v) =>
                                                                          v !==
                                                                          option.value,
                                                                  )
                                                                : [
                                                                      ...prev,
                                                                      option.value,
                                                                  ],
                                                        );
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={typeFilter.includes(
                                                            option.value,
                                                        )}
                                                        onChange={() => {}}
                                                        className={`mr-2 ${
                                                            typeFilter.length ===
                                                            5
                                                                ? 'opacity-50'
                                                                : ''
                                                        }`}
                                                    />
                                                    {option.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </span>
                    <span className="inline-title text-sm text-gray-700 ml-4">
                        <p className="mt-2 text-sm text-gray-700">
                            &nbsp;&nbsp; Include Hidden?
                            <input
                                type="checkbox"
                                onChange={() => setShowHidden(!showHidden)}
                                checked={showHidden}
                            />
                        </p>
                    </span>
                    <span className="inline-title text-sm text-gray-700 ml-4">
                        <p className="mt-2 text-sm text-gray-700">
                            Sort:
                            <select
                                onChange={(e) =>
                                    setSortOrder(
                                        e.target.value as 'asc' | 'desc',
                                    )
                                }
                                value={sortOrder}
                            >
                                <option value="asc">Oldest First</option>
                                <option value="desc">Newest First</option>
                            </select>
                        </p>
                    </span>
                    <span className="inline-title text-sm text-gray-700 ml-4">
                        <p className="mt-2 text-sm text-gray-700">
                            Columns:
                            <select
                                onChange={(e) =>
                                    setColumnCount(parseInt(e.target.value))
                                }
                                value={columnCount}
                            >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </select>
                        </p>
                    </span>
                </div>
                <div className="grow" />
                {selectedShareIdentifiers.length > 0 && (
                    <div className="fixed bottom-[30px] left-[30px] flex flex-row items-center bg-white rounded-lg p-2 border border-gray-300 shadow-lg">
                        <>
                            <div className="flex flex-row items-center">
                                <span
                                    className="mt-3 inline-flex w-full ml-4 cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={() => {
                                        setSelectedShareIdentifiers([]);
                                    }}
                                >
                                    Clear Selected
                                </span>
                            </div>
                            <span className="inline-title relative flex flex-row items-center  gap-5 cursor-pointer text-sm text-gray-700 ml-4 bg-gray-100 rounded-lg p-1 border border-gray-300">
                                {isHidden ? (
                                    <EyeIcon
                                        className="h-6 w-6"
                                        onClick={async () => {
                                            await authenticatedApi.put(
                                                `/media/share-identifiers/visibility`,
                                                {
                                                    shareIdentifiers:
                                                        selectedShareIdentifiers,
                                                    isHidden: false,
                                                },
                                            );

                                            // set isHidden to true for all photos in selectedShareIdentifiers
                                            const newPhotos = photos.map(
                                                (photo) => {
                                                    if (
                                                        selectedShareIdentifiers.includes(
                                                            photo.shareIdentifier,
                                                        )
                                                    ) {
                                                        photo.isHidden = false;
                                                    }

                                                    return photo;
                                                },
                                            );

                                            setPhotos(newPhotos);
                                            setIsHidden(false);

                                            toast.success(
                                                'Photos set to visible',
                                            );
                                        }}
                                    />
                                ) : (
                                    <EyeSlashIcon
                                        className="h-6 w-6"
                                        title="Hide Photos"
                                        onClick={async () => {
                                            await authenticatedApi.put(
                                                `/media/share-identifiers/visibility`,
                                                {
                                                    shareIdentifiers:
                                                        selectedShareIdentifiers,
                                                    isHidden: true,
                                                },
                                            );

                                            // set isHidden to true for all photos in selectedShareIdentifiers
                                            const newPhotos = photos.map(
                                                (photo) => {
                                                    if (
                                                        selectedShareIdentifiers.includes(
                                                            photo.shareIdentifier,
                                                        )
                                                    ) {
                                                        photo.isHidden = true;
                                                    }

                                                    return photo;
                                                },
                                            );

                                            setPhotos(newPhotos);
                                            setIsHidden(true);

                                            toast.success(
                                                'Photos set to hidden',
                                            );
                                        }}
                                    />
                                )}
                                <p className="text-[10px] text-gray-700 absolute -top-2 -right-2 bg-gray-100 rounded-full px-1 py-0 border border-gray-300">
                                    {selectedShareIdentifiers.length}
                                </p>
                            </span>
                        </>
                    </div>
                )}
                <span className="inline-title text-sm text-gray-700 ml-4">
                    <span
                        className="inline-title text-sm text-gray-700 ml-4 cursor-pointer"
                        onClick={async () => {
                            if (!activationId) {
                                return;
                            }

                            setIsReprocessing(true);

                            const result = await authenticatedApi.post(
                                `/activations/${activationId}/reprocessThumbnails`,
                            );

                            const data = result.data;

                            toast.success(
                                `Reprocessed ${data.updated} photos of ${data.count}.`,
                            );
                            setIsReprocessing(false);
                        }}
                    >
                        {isReprocessing ? (
                            <div role="status" className="ml-2">
                                <svg
                                    aria-hidden="true"
                                    className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                            </div>
                        ) : (
                            <LucideGalleryThumbnails
                                name=""
                                className="h-6 w-6 cursor-pointer"
                            />
                        )}
                    </span>
                </span>
                <span className="inline-title text-sm text-gray-700 ml-4">
                    <DownloadIcon
                        className="h-6 w-6"
                        onClick={() => {
                            if (!activationId) {
                                return;
                            }

                            setIsShowingZipPrompt(true);
                        }}
                    />
                </span>
                <span className="inline-title">
                    <UploadIcon
                        onClick={() => setIsShowingBulkUpload(true)}
                        className="h-6 w-6"
                    />
                </span>
            </div>
        </div>
    );
}
