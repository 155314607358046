import { useCallback, useState } from 'react';
import { saveAs } from 'file-saver';
import usePhotoDownloadLogger from '../../Stats/components/hooks/usePhotoDownloadLogger';

interface ShareSheetProps {
    uri: string;
    mimeType: string;
    mediaId: number;
    source: 'gallery' | 'personal-gallery' | 'share';
    children: React.ReactNode;
}

const MIME_LOOKUP: { [key: string]: string } = {
    'video/mp4': 'mp4',
    'image/jpeg': 'jpg',
    'image/gif': 'gif',
    'image/png': 'png',
};

const getFileName = (url: string, mimeType: string): string => {
    const basename = url.split('/').reverse()[0];
    const extension = MIME_LOOKUP[mimeType];
    if (basename.split('.').length === 1) {
        return `${basename}.${extension}`;
    }
    return basename;
};

export default function ShareSheetV2({
    uri,
    mimeType,
    mediaId,
    source,
    children,
}: ShareSheetProps) {
    const { logPhotoDownload } = usePhotoDownloadLogger();
    const [isDownloading, setIsDownloading] = useState(false);

    const download = useCallback(
        async (e: any) => {
            let url = `${uri}?x-request=download-share`;
            const prefetch = async () => {
                return new Promise<void>((resolve, reject) => {
                    const image = new Image();

                    if (mimeType === 'video/mp4') {
                        resolve();
                        return;
                    }

                    let url = `${uri}?x-request=download`;
                    image.src = url;
                    image.onload = () => {
                        resolve();
                    };
                    image.onerror = (error) => {
                        reject(error);
                    };
                });
            };

            e.stopPropagation();
            setIsDownloading(true);

            await prefetch();

            logPhotoDownload(mediaId, source);

            const canShare = !!navigator.canShare;

            if (canShare) {
                try {
                    const response = await fetch(url, {
                        method: 'GET',
                    });

                    console.log(
                        `${mediaId}${
                            mediaId.toString().split('.').length === 1
                                ? `.${MIME_LOOKUP[mimeType]}`
                                : ''
                        }`,
                    );

                    const file = new File(
                        [await response.blob()],
                        `${mediaId}${
                            mediaId.toString().split('.').length === 1
                                ? `.${MIME_LOOKUP[mimeType]}`
                                : ''
                        }`,
                    );

                    const files = [file];
                    const shareData = {
                        files: files,
                    };

                    if (navigator?.canShare(shareData)) {
                        try {
                            await navigator.share(shareData);
                            setIsDownloading(false);
                            return;
                        } catch (e) {
                            console.log('Share failed', e);
                        }

                        setIsDownloading(false);
                    }
                } catch (e) {
                    console.log('Share failed', e);
                }
            }

            try {
                fetch(uri, {
                    method: 'GET',
                })
                    .then((response) => {
                        return response.blob();
                    })
                    .then((blob: Blob) => {
                        saveAs(blob, getFileName(uri, mimeType));
                    })
                    .catch(() => {
                        saveAs(url, getFileName(uri, mimeType));
                    });
            } catch (e) {
                console.error('Download failed', e);
            } finally {
                setIsDownloading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [uri, setIsDownloading],
    );

    return (
        <div onClick={download} className="relative">
            {isDownloading && <div className="loader absolute" />}
            {children}
        </div>
    );
}
