import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import GalleryContext from '@/src/Gallery/components/GalleryContext';
import useQueryString from '@/src/Common/components/hooks/useQueryString';
import DownloadButtonV2 from '@/src/Share/components/DownloadButtonV2';
import React from 'react';
import useGalleryBehaviors from '@/src/Gallery/components/hooks/useGalleryBehvaiors';
import GalleryLightbox from '@/src/Gallery/components/GalleryLightbox';
import {
    useSelectionContainer,
    Box,
    boxesIntersect,
} from '@air/react-drag-to-select';
import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import ReplaceMedia from '@/src/Common/components/ReplaceMedia';
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';

interface PhotoManagerListProps {
    photos: MediaInstance[];
    setSelectedShareIdentifiers: (shareIdentifiers: string[]) => void;
    selectedShareIdentifiers: string[];
    columnCount: number;
}

let columnHeights: number[] = [];

export default function PhotoManagerList({
    photos,
    setSelectedShareIdentifiers,
    selectedShareIdentifiers,
    columnCount,
}: PhotoManagerListProps) {
    const [columns, setColumns] = useState<number>(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const GAP = 0; // Gap between images in pixels
    const [columnWidth, setColumnWidth] = useState<number>(0);
    const query = useQueryString();
    const urlShareIdentifier = query.get('sid');
    const [shareIdentifierToIndexLookup, setShareIdentifierToIndexLookup] =
        useState<{ [key: string]: number }>({});

    // Calculate number of columns based on container width
    useEffect(() => {
        const updateColumns = () => {
            const width = containerRef.current?.offsetWidth ?? 0;
            /*if (width < 1200) {
                setColumns(2);
                setColumnWidth(width / 2);
            } else if (width < 1800) {
                setColumns(3);
                setColumnWidth(width / 3);
            } else {
                setColumns(4);
                setColumnWidth(width / 4);
            }*/
            setColumns(columnCount);
            setColumnWidth(width / columnCount);
        };

        updateColumns();
        window.addEventListener('resize', updateColumns);
        return () => window.removeEventListener('resize', updateColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnCount]);

    // Calculate positions for each photo
    const getPhotoPositions = useMemo(() => {
        columnHeights = new Array(columns).fill(0);
        return photos.map((photo, index) => {
            // @ts-ignore
            const aspectRatio =
                // @ts-ignore
                photo.aspectRatio || 1.5;
            const width = `${columnWidth}px`;
            const shortestColumn = columnHeights.indexOf(
                Math.min(...columnHeights),
            );
            const x = `${
                shortestColumn * (100 / columns) + shortestColumn * GAP
            }%`;
            const y = columnHeights[shortestColumn];

            const photoHeight = columnWidth / aspectRatio;
            columnHeights[shortestColumn] += photoHeight + GAP;

            return {
                id: photo.id,
                style: {
                    position: 'absolute',
                    left: x,
                    top: y,
                    width,
                    height: `${photoHeight}px`,
                    display: 'flex',
                },
                shareIdentifier: photo.shareIdentifier,
                thumbnail: photo.thumbnail_800pxUri,
                full: photo.originalUri,
                tiny: photo.thumbnailTinyUri,
                isHidden: photo.isHidden,
                isFavorite: photo.isFavorite,
                mimeType: photo.mimeType,
                imageStyle: {
                    objectFit: 'contain',
                    backgroundSize: 'cover',
                    backgroundColor: 'rgba(0,0,0,0.03)',
                    transition: 'all 0.3s linear',
                    margin: 4,
                    flex: 1,
                },
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photos, columns, columnWidth, columnCount]);

    const [shareIdentifier, setShareIdentifier] = useState<string | null>(
        urlShareIdentifier ?? null,
    );
    const [photoIndex, setPhotoIndex] = useState<number>(0);
    /*const [searchParams, setSearchParams] = useSearchParams();
    const currentSearchParams = Object.fromEntries(
        Array.from(searchParams.entries()).filter(
            ([key]: [string, string]) => key !== 'sid',
        ),
    );*/

    useEffect(() => {
        let lookup: { [key: string]: number } = {};
        photos.forEach((photo, index) => {
            lookup[photo.shareIdentifier] = index;
        });

        setShareIdentifierToIndexLookup(lookup);
    }, [photos]);

    useEffect(() => {
        if (shareIdentifier) {
            setPhotoIndex(shareIdentifierToIndexLookup[shareIdentifier] ?? 0);

            //      setSearchParams({ ...currentSearchParams, sid: shareIdentifier });

            const element = document.getElementById(
                `${shareIdentifier}-element`,
            );
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            /*     setSearchParams({
                ...currentSearchParams,
            });*/
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        shareIdentifierToIndexLookup,
        shareIdentifier,
        photos,
        setPhotoIndex,
        /*setSearchParams,
        searchParams,*/
    ]);

    const { swipeHandlers } = useGalleryBehaviors(
        () => {
            setShareIdentifier(null);
        },
        () => {
            if (!shareIdentifier) {
                return;
            }

            let index = shareIdentifierToIndexLookup[shareIdentifier] ?? 0;

            if (index >= 0 && photos[index + 1]) {
                setShareIdentifier(photos[index + 1].shareIdentifier);
            } else {
                setShareIdentifier(null);
            }
        },
        () => {
            if (!shareIdentifier) {
                return;
            }

            let index = shareIdentifierToIndexLookup[shareIdentifier] ?? 0;

            if (index >= 0 && photos[index - 1]) {
                setShareIdentifier(photos[index - 1].shareIdentifier);
            } else {
                setShareIdentifier(null);
            }
        },
    );

    return (
        <GalleryContext.Provider
            value={{ shareIdentifier, setShareIdentifier }}
        >
            <div
                ref={containerRef}
                className="relative w-full"
                style={{ height: `${Math.max(...columnHeights)}px` }}
            >
                <GalleryLayout
                    key={columnCount}
                    layout={getPhotoPositions}
                    setShareIdentifier={setShareIdentifier}
                    setSelectedShareIdentifiers={setSelectedShareIdentifiers}
                    containerRef={containerRef}
                    selectedShareIdentifiers={selectedShareIdentifiers}
                />
            </div>
            <GalleryLightbox
                photos={photos}
                currentPhotoIndex={photoIndex}
                gestureHandlers={swipeHandlers}
            />
        </GalleryContext.Provider>
    );
}

const GalleryLayout = React.memo(function GalleryLayout({
    layout,
    setShareIdentifier,
    setSelectedShareIdentifiers,
    selectedShareIdentifiers,
    containerRef,
}: {
    layout: any[];
    setShareIdentifier: (shareIdentifier: string) => void;
    setSelectedShareIdentifiers: (shareIdentifiers: string[]) => void;
    selectedShareIdentifiers: string[];
    containerRef: React.RefObject<HTMLDivElement>;
}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setSelectionBox] = useState<Box>();
    const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
    const selectableItems = useRef<Box[]>([]);

    useEffect(() => {
        setSelectedIndexes(
            selectedShareIdentifiers.map((shareIdentifier) => {
                return layout.findIndex(
                    (item) => item.shareIdentifier === shareIdentifier,
                );
            }),
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShareIdentifiers]);

    useHotkeys('mod+a', (e) => {
        e.preventDefault();
        setSelectedShareIdentifiers(layout.map((item) => item.shareIdentifier));
    });

    const { DragSelection } = useSelectionContainer({
        eventsElement: document.getElementById('root'),
        onSelectionChange: (box) => {
            const scrollAwareBox: Box = {
                ...box,
                top: box.top + window.scrollY,
                left: box.left + window.scrollX,
            };

            setSelectionBox(scrollAwareBox);
            const indexesToSelect: number[] = [];

            selectableItems.current.forEach((item, index) => {
                if (boxesIntersect(scrollAwareBox, item)) {
                    indexesToSelect.push(index);
                }
            });

            setSelectedShareIdentifiers(
                selectedIndexes.map((index) => {
                    return layout[index].shareIdentifier;
                }),
            );
            setSelectedIndexes(indexesToSelect);
        },
        onSelectionStart: () => {
            console.log('OnSelectionStart');
        },
        onSelectionEnd: () => console.log('OnSelectionEnd'),
        selectionProps: {
            style: {
                border: '1px solid black',
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: 1000,
            },
        },
        isEnabled: true,
    });

    useEffect(() => {
        setTimeout(() => {
            if (containerRef.current) {
                Array.from(containerRef.current.children).forEach(
                    (item, index) => {
                        if (index === 0) {
                            return;
                        }

                        const { left, top, width, height } =
                            item.getBoundingClientRect();

                        selectableItems.current.push({
                            left,
                            top,
                            width,
                            height,
                        });
                    },
                );
            }
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <DragSelection />
            {layout.map(
                (
                    {
                        id,
                        style,
                        imageStyle,
                        thumbnail,
                        full,
                        tiny,
                        shareIdentifier,
                        mimeType,
                        isHidden,
                        isFavorite,
                    },
                    index,
                ) => (
                    // @ts-ignore
                    <div
                        key={`${shareIdentifier}-gutter-${isHidden}`}
                        data-share-identifier={shareIdentifier}
                        // @ts-ignore
                        style={{
                            ...(style ?? {}),
                            border: selectedIndexes.includes(index)
                                ? '1px solid rgba(0,0,0,0.5)'
                                : '1px solid transparent',
                            transition: 'all 0.3s linear',
                        }}
                        onClick={(e) => {
                            let isSelecting = isHotkeyPressed('shift');
                            if (!isSelecting) {
                                setSelectedShareIdentifiers([shareIdentifier]);
                                return;
                            }
                            e.stopPropagation();

                            if (
                                selectedShareIdentifiers.includes(
                                    shareIdentifier,
                                )
                            ) {
                                setSelectedShareIdentifiers(
                                    selectedShareIdentifiers.filter(
                                        (id) => id !== shareIdentifier,
                                    ),
                                );
                            } else {
                                setSelectedShareIdentifiers([
                                    ...selectedShareIdentifiers,
                                    shareIdentifier,
                                ]);
                            }
                        }}
                    >
                        <PhotoPreview
                            full={full}
                            thumbnail={thumbnail || full}
                            tiny={tiny}
                            style={imageStyle ?? {}}
                            shareIdentifier={shareIdentifier}
                            id={id}
                            mimeType={mimeType}
                            setShareIdentifier={setShareIdentifier}
                            isHidden={isHidden}
                            isFavorite={isFavorite}
                        />
                    </div>
                ),
            )}
        </>
    );
});

function PhotoPreview({
    style,
    thumbnail,
    tiny,
    shareIdentifier,
    id,
    mimeType,
    full,
    setShareIdentifier,
    isHidden,
    isFavorite,
}: {
    style: any;
    thumbnail: string;
    tiny: string;
    shareIdentifier: string;
    id: number;
    mimeType: string;
    full: string;
    isHidden: boolean;
    isFavorite: boolean;
    setShareIdentifier: (shareIdentifier: string) => void;
}) {
    const [uri, setUri] = useState<string>(tiny || thumbnail);
    const [, setIsLoading] = useState<boolean>(true);
    const ref = useRef<HTMLDivElement>(null);
    const visibility = useIsVisible(ref);
    const hasLoaded = useRef<boolean>(false);
    const [isFavoriting, setIsFavoriting] = useState<boolean>(false);
    const [isReplacing, setIsReplacing] = useState<boolean>(false);
    const [isHiding, setIsHiding] = useState<boolean>(false);
    const [localIsHidden, setLocalIsHidden] = useState<boolean>(isHidden);
    const [localIsFavorite, setLocalIsFavorite] = useState<boolean>(isFavorite);

    const authenticatedApi = useAuthenticatedApi();
    const toggleHidden = async (
        mediaId: number,
        isHidden: boolean,
    ): Promise<boolean> => {
        const toggleVisibility = async () => {
            await authenticatedApi.put(`media/${mediaId}/visibility`, {
                isHidden: !isHidden,
            });

            return !isHidden;
        };

        return await toggleVisibility();
    };

    const toggleFavorite = async (
        mediaId: number,
        isFavorite: boolean,
    ): Promise<boolean> => {
        const toggle = async () => {
            await authenticatedApi.put(`media/${mediaId}/favorite`, {
                isFavorite: !isFavorite,
            });

            return !isFavorite;
        };

        return await toggle();
    };

    useEffect(() => {
        if (visibility) {
            const image = new Image();
            image.src = thumbnail;
            image.onload = () => {
                setUri(thumbnail);
                setIsLoading(false);
            };
        } else {
            setUri(tiny || thumbnail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thumbnail, visibility]);

    useEffect(() => {
        if (visibility) {
            hasLoaded.current = true;
        }
    }, [visibility]);

    const handleExpandClick = useCallback(
        (e: any) => {
            setShareIdentifier(shareIdentifier);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [shareIdentifier],
    );

    if (!visibility && !hasLoaded.current) {
        return (
            <div
                ref={ref}
                key={shareIdentifier}
                style={{
                    ...style,
                    position: 'relative',
                    transition: 'all 0.3s linear',
                }}
            ></div>
        );
    }

    return (
        <div
            ref={ref}
            key={shareIdentifier}
            style={{
                ...style,
                position: 'relative',
                backgroundImage:
                    visibility || hasLoaded.current ? `url(${uri})` : 'none',
                opacity: localIsHidden ? 0.5 : 1,
                transition: 'all 0.3s linear',
            }}
            className="bg-no-repeat group"
            id={`${shareIdentifier}-element`}
        >
            <>
                {mimeType === 'video/mp4' && (
                    <div className="button-container">
                        <i
                            className="bi bi-play"
                            style={{ filter: 'drop-shadow(0px 0px 5px #222)' }}
                        />
                    </div>
                )}

                <div className="absolute bottom-0  right-0 left-0 bg-gradient-to-t from-[rgba(0,0,0,0.5)] to-transparent opacity-80 h-[60px] invisible group-hover:invisible md:group-hover:visible">
                    <div className="flex justify-start items-end h-full gap-0 flex-row text-white px-4 pb-4">
                        <div
                            onClick={async (event: any) => {
                                setTimeout(() => setIsFavoriting(true), 0);
                                event.stopPropagation();
                                event.preventDefault();

                                const newState = await toggleFavorite(
                                    id,
                                    localIsFavorite,
                                );
                                setLocalIsFavorite(newState);
                                setTimeout(() => setIsFavoriting(false), 0);
                            }}
                        >
                            <i
                                className={`bi ${
                                    isFavoriting
                                        ? 'bi-hourglass'
                                        : !localIsFavorite
                                        ? 'bi-heart'
                                        : 'bi-heart-fill'
                                } clickable`}
                            />
                        </div>
                        &nbsp; &nbsp;
                        <div
                            onClick={async (event: any) => {
                                event.stopPropagation();
                                event.preventDefault();

                                setIsReplacing(true);
                            }}
                        >
                            <i className={`bi bi-pencil clickable`} />
                        </div>
                        &nbsp; &nbsp;
                        <div
                            onClick={async (event: any) => {
                                setTimeout(() => setIsHiding(true), 0);
                                event.stopPropagation();
                                event.preventDefault();

                                const newState = await toggleHidden(
                                    id,
                                    isHidden,
                                );

                                setLocalIsHidden(newState);

                                setTimeout(() => setIsHiding(false), 0);
                            }}
                        >
                            <i
                                className={`bi ${
                                    isHiding
                                        ? 'bi-hourglass'
                                        : !localIsHidden
                                        ? 'bi-eye'
                                        : 'bi-eye-slash'
                                } clickable`}
                            />
                        </div>
                        &nbsp; &nbsp;
                        <DownloadButtonV2
                            uri={full}
                            mimeType={mimeType}
                            mediaId={id}
                            source="gallery"
                        />
                        <div className="spacer" />
                        <div onClick={handleExpandClick}>
                            <i className="bi bi-fullscreen clickable" />
                        </div>
                    </div>
                </div>
            </>
            {isReplacing && (
                <ReplaceMedia
                    mediaId={id}
                    onComplete={(uri: string) => {
                        setUri(uri);

                        setIsReplacing(false);
                    }}
                    onCancel={() => {
                        setIsReplacing(false);
                    }}
                />
            )}
        </div>
    );
}

export function useIsVisible(ref: React.RefObject<HTMLDivElement>) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            {
                rootMargin: '300px 0px 600px 0px',
            },
        );

        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
}
